.salesattack-header__section
  background-image: url("/images/app/sales-attack/img--bg-salesattack.jpg")
  background-position: bottom
  min-height: 412px
  overflow: hidden
  position: relative

  video
    position: absolute
    z-index: 1
    left: 0
    right: 0
    top: 50%
    transform: translateY(-50%)
    object-fit: cover
    border-radius: 1px
    width: 100%
    height: 100%
    overflow: hidden

    .oldie &
      height: auto

  &.app-header__section--open
    height: auto

    .app-header__box
      display: none

    +mq($until: tablet)
      .app-header__left h3,
      .app-header__left p
        margin-left: auto
        margin-right: auto

  +mq($from: mobile)
    min-height: 540px

    &.app-header__section--open
      height: auto

      .app-header__box
        display: block
        right: -200px

  +mq($from: tablet)
    min-height: 310px

    &.app-header__section--open
      height: auto

      .app-header__box
        margin-bottom: 0
        width: 896px
        height: 622px
        left: 45%
        bottom: 0

        &--mg
          width: 329px
          height: 163px
          right: -95px
          bottom: 0

  .app-header__box
    line-height: 0
    margin-bottom: 0
    display: block
    width: 327px
    height: 227px
    left: auto
    right: -25px
    bottom: 0
    z-index: 2

    +mq($from: mobile)
      width: 412px
      height: 287px
      right: 0
      bottom: -40px

