.homepage

  // Light Mode
  &.light-mode
    .navbar__user-bar,
    .product__price,
    .button-wishlist--active
      background-color: $c-darkgrey

    .footer,
    .profile-address__add-new:hover .profile-address__icon, 
    .profile-address__add-new:focus .profile-address__icon,
    .product-view .details__price,
    .reward-categories__highlights-all
      background-color: $c-black

    .product__price--shop,
    .cart-product__price,
    .c-orange
      color: $c-darkgrey

    .profile-header__add-new:hover .icon svg use, 
    .profile-header__add-new:focus .icon svg use,
    .navbar__micro-menu__item:hover > .icon svg use, 
    .navbar__micro-menu__item a:hover > .icon svg use, 
    .navbar__micro-menu__item:focus > .icon svg use,
    .navbar__micro-menu__item a:focus > .icon svg use
      fill: $c-black

    .footer__links a:hover
      border-color: $c-white

    .navbar__user .button--has-icon.button--noOutline:hover .icon svg use, 
    .navbar__user .button--has-icon.button--noOutline:focus .icon svg use
      fill: $c-lightgrey

    .navbar__user .button--has-icon.button--noOutline:hover, 
    .navbar__user .button--has-icon.button--noOutline:focus,
    .button--noOutline:hover, 
    .button--noOutline:focus,
    .account__filter > h2
      color: $c-brightgrey

    // Buttons
    .button--primary
      background-color: $c-black

      &:hover, &:focus
        background-color: $c-black
        color: $c-white

      &.button--has-icon
        &:hover, &:focus
          svg use
            fill: $c-white

    .button--secondary
      border-color: $c-black

      &:hover, &:focus
        background-color: $c-black
        color: $c-white

      &.button--has-icon
        &:hover, &:focus
          svg use
            fill: $c-white

    .button--secondary-inverted
      border-color: $c-white

      &:hover, &:focus
        background-color: $c-white
        color: $c-black

    .button-points
      background-color: $c-black

  // Dark Mode
  &.dark-mode
  
  .homepage-wrapper
    .section__card-slider
      padding-top: 100px
  