.news-content-detail
  box-sizing: border-box
  width: 100%
  position: relative
  padding: 120px 0 60px
  max-width: 800px
  margin: 0 auto

  +mq($until: mobile)
    padding-left: 20px
    padding-right: 20px
    padding-top: 100px

  h2
    text-align: center
    margin-bottom: 50px

  ul
    list-style: none
    margin: 0 0 40px
    padding: 0

    li
      margin-bottom: 20px

  &--empty
    text-align: center

  &__item
    padding: 20px
    border-radius: 8px
    display: flex
    flex-wrap: wrap
    position: relative
    width: 100%
    box-sizing: border-box
    border: 2px solid $c-lightgrey
    transition: all 0.3s ease-in-out

    &--new
      background-color: $c-lightgrey

    &--active
      background-color: $c-lightgrey
      
      .news-content-detail__content--full
        overflow: inherit
        height: auto

      .news-content-detail__content .icon
        transform: rotate(-90deg)

      .news-content-detail__content
        order: 0
        padding-left: 0
        flex: 0 0 100%
        max-width: 100%
        margin-bottom: 20px

        p
          display: none

      .news-content-detail__image
        order: 1
        flex: 0 0 100%
        max-width: 100%
        margin-bottom: 20px

        // img
        //   max-width: 350px

      .news-content-detail__content small
        display: block
        margin-top: 20px

    
  &__image
    flex: 0 0 30%
    max-width: 30%
    box-sizing: border-box

    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      margin-bottom: 30px

    img
      max-width: 100%
      border-radius: 8px

    cite
      display: block
      font-size: 12px
      margin-top: 5px

    &--empty
      flex: 0 0 0
      max-width: 0

  &__content
    flex: 0 0 70%
    max-width: 70%
    padding: 0 24px 0 24px
    box-sizing: border-box
    position: relative

    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      padding: 0

    &--full
      height: 0
      overflow: hidden
      box-sizing: border-box
      order: 2
      width: 100%

      .editor
        white-space: normal

        i
          font-style: italic 

        h1
          font-size: 26px
          margin-bottom: 10px

        h2
          font-size: 24px
          text-align: left
          text-transform: none
          margin-bottom: 15px

    &--fullwidth
      flex: 0 0 100%
      max-width: 100%
      padding-left: 0
      padding-top: 40px

    .icon
      position: absolute
      top: 18px
      right: 0
      transform: rotate(90deg)
      +transition

    small
      font-size: 14px

    h3
      font-size: 32px
      margin-bottom: 5px
      max-width: 90%

      +mq($until: mobile)
        font-size: 28px
    
    p
      font-size: 16px
      margin-bottom: 10px

    button
      height: 42px
      line-height: 42px
      padding: 0 40px

    .editor
      white-space: normal

      i
        font-style: italic 

      h1
        font-size: 26px
        margin-bottom: 10px

      h2
        font-size: 24px
        text-align: left
        text-transform: none
        margin-bottom: 15px

  &__action
    text-align: right

  &__badge
    position: absolute
    top: -10px
    left: 25px
    background-color: #939393
    color: $c-white
    font-size: 24px
    font-weight: bold
    padding: 8px 18px 4px
    border-radius: 8px

  &__all-news
    width: 100%
    text-align: center

    button
      border-color: $c-darkblue

      &:hover
        border-color: $c-turquoise