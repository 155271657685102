.contracts
  background-color: $c-darkblue
  max-width: 1440px
  box-sizing: border-box
  
  &__content
    padding: 60px 130px
    color: $c-white
    display: flex
    flex-wrap: wrap

    +mq($until: tablet)
      padding: 60px 20px

    h2
      text-align: center
      flex: 0 0 100%
      margin-bottom: 40px

      button
        float: left
        background-color: $c-blue
        padding: 0
        width: 40px
        height: 40px

        .icon
          width: 20px
          transform: rotate(-180deg)
          top: 6px
          left: 8px

          svg use
            fill: $c-white

  
  &__left
    flex: 0 0 auto
    width: calc(77% - 10px)
    padding-right: 10px

    +mq($until: desktop)
      width: 100%
      order: 2
      padding: 0

  &__right
    flex: 0 0 auto
    width: calc(23% - 10px)
    padding-left: 10px

    +mq($until: desktop)
      width: 100%
      order: 1
      margin-bottom: 40px
      padding: 0

  // Table Styles
  &__table
    &--closed
      .contracts__row
        cursor: auto

    +mq($until: mobile)
      width: 100%
      overflow: auto

  &__head
    +mq($until: mobile)
      width: 800px
    
    .contracts__row
      background: transparent
      margin: 0
      cursor: auto

    .contracts__cell
      font-size: 16px
      font-weight: 700

  &__body
    +mq($until: mobile)
      width: 800px

  &__row,
  &__empty
    display: flex
    flex-wrap: wrap
    background: linear-gradient(0deg, rgba(31,31,62,1) 0%, rgba(34,34,72,1) 100%)
    padding: 8px 20px
    border-radius: 8px
    margin-bottom: 10px
    cursor: pointer
    align-items: center

    &--active
      background: linear-gradient(-90deg, rgba(70,70,118,1) 0%, rgba(54,54,105,1) 100%)

      .item-index
        background-color: $c-turquoise !important
    
    &--assigned
      background: linear-gradient(0deg, rgba(31,31,31,1) 0%, rgba(70,70,70,1) 100%)
      opacity: 0.6
      cursor: auto

      svg use
        fill: $c-white
  
  &__empty
    cursor: auto

  &__cell
    &:nth-child(1)
      flex: 0 0 8%

    &:nth-child(2)
      flex: 0 0 15%

    &:nth-child(3)
      flex: 0 0 32%
      
    &:nth-child(4)
      flex: 0 0 15%
      font-weight: 700

    &:nth-child(5),
    &:nth-child(6)
      flex: 0 0 15%
    
    .item-index
      width: 40px
      height: 30px
      border-radius: 50%
      display: block
      text-align: center
      background-color: $c-darkblue
      padding-top: 10px

      .num
        position: relative
        top: 2px

      .icon
        width: 20px

        &.active
          svg use
            fill: $c-white
      
    .shorten-txt
      max-width: 215px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      display: block
    
    .points-txt
      margin-left: 5px

  // Box
  &__box
    padding: 20px
    background: linear-gradient(0deg, rgba(31,31,62,1) 0%, rgba(34,34,72,1) 100%)
    border-radius: 8px
    margin-top: 34px

    h4
      margin-bottom: 20px

    button
      width: 100%
      padding: 0 20px

      &:disabled
        opacity: 0.2

    input
      border: none
      width: 100%
      border-radius: 8px
      font-size: 16px
      padding: 15px 15px
      margin-bottom: 20px

      &:focus
        outline: none !important
        border: none !important

  // Archive
  &__archive
    padding: 30px
    background: linear-gradient(0deg, rgba(31,31,62,1) 0%, rgba(34,34,72,1) 100%)
    border-radius: 8px
    display: flex
    justify-content: space-between
    align-items: center
    max-width: 450px
    float: right
    margin-top: 30px


  // Pagination
  .pagination
    margin-top: 20px

    li
      margin: 0 5px

      &.active
        button
          background-color: $c-blue
          color: $c-white

      button
        width: 30px
        height: 30px
        border-radius: 50%
        border: none
        background-color: transparent
        text-align: center
        transition: all 0.3s ease-in-out
        cursor: pointer
        color: $c-white

        &:hover
          background-color: $c-blue
          color: $c-white

        .icon
          svg, use
            fill: $c-white
        
        .sr-only
          display: none

        .icon
          max-width: 15px
          height: auto
          position:  relative
          top: 1px
          left: 2px

          &.arrow--left
            transform: rotate(-180deg)
            left: -2px