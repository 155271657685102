.schaffe-ich-room

  &__small-text
    font-size: 20px
    position: relative
    top: -13px

    +mq($until: mobile)
      top: -4px

  &__section
    padding: 60px 20px 40px
    display: flex
    flex-direction: column
    background-color: $c-darkblue
    position: relative

    +mq($from: mobile)
      padding: 60px 40px 60px
    +mq($from: desktop)
      padding: 40px 60px 40px

    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      z-index: 62
      transform: translate(-50%, -50%)

  &__content
    display: flex
    flex-direction: column

    .card-slider__label
      color: $c-white

    +mq($from: tablet)
      align-items: center

  &__wrapper
    width: stretch
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    +transition

    .schaffe-ich-room__timer-wrapper
      display: flex
      //position: absolute
      z-index: 50
      background-color: $c-darkblue
      align-items: center
      max-width: 530px
      border-top-left-radius: 8px
      border-top-right-radius: 8px
      right: 130px
      bottom: 0
      color: $c-white
      margin-bottom: 40px
      flex-direction: column

      +mq($from: tablet)
        flex-direction: row
        max-height: 166px

      & > h2
        +fontsize(22px, 28px)
        font-weight: normal
        max-width: 228px
        text-align: center
        margin-bottom: 20px

        +mq($from: tablet)
          margin-bottom: 0
          max-width: none
          text-align: left


      .salespush-performance__box--timer
        max-width: max-content
        max-height: 126px
        padding: 20px 10px

        +mq($from: tablet)
          margin-left: 20px

        .progress-circle
          font-size: 86px

          & > span:first-of-type
            font-size: 39px
            line-height: 39px

  &__information
    max-height: 0
    overflow: hidden
    color: $c-white
    margin-bottom: 20px
    +transition

    & > .button
      margin-top: 20px
      left: 50%
      transform: translateX(-50%)

    & > h3
      margin-bottom: 20px

  &__headline
    color: $c-white
    margin: 20px 0 20px
    text-align: center
    +transition

    +mq($from: mobile)
      margin: 20px 0 20px
      +transition

    +mq($from: tablet)
      margin: 20px 0
      text-align: left
      +transition

    & > h2
      +mq($until: tablet)
        +fontsize(26px, 32px)

  &__boxes
    display: flex
    justify-content: center
    width: 100%
    width: stretch
    flex-wrap: wrap

    +mq($from: mobile)
      margin: -10px
      flex-direction: row
      flex-wrap: wrap

    .lottie__uncontrolled svg
      transform: translate3d(0px, 2px, 0px) !important

  &__loader
    width: 100%
    width: stretch
    border-radius: 25px
    height: 13px
    background-color: $c-grey
    overflow: hidden

    &-indicator
      display: block
      width: 50%
      height: 100%
      background-color: $c-orange

  &__challenge
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 60px
    display: none

    +mq($from: tablet)
      display: flex

    &--tablet
      display: flex
      flex-direction: column
      +mq($from: tablet)
        display: none

    &-goal, &-win
      width: 200px
      height: 110px
      border-top-left-radius: 8px
      border-bottom-left-radius: 8px
      background-color: $c-lightgrey
      padding: 10px
      box-sizing: border-box
      justify-content: space-between
      align-items: center
      display: flex
      flex-direction: column

      .schaffe-ich-room__challenge--tablet &
        width: 280px
        height: 110px
        border-radius: 0
        +transition

      & > h4
        text-align: center

      & > h2
        +fontsize(42px, 42px)
        font-weight: bold
        color: $c-orange

        & > sup
          +fontsize(21px, 21px)

    &-goal
      position: relative
      z-index: 5

      .schaffe-ich-room__challenge--tablet &
        +drop-shadow

    &-win
      width: 220px
      z-index: 4

      & > h4
        width: 170px

      .schaffe-ich-room__challenge--tablet &
        border-bottom-right-radius: 8px
        border-bottom-left-radius: 8px

        & > h4
          max-width: 100px

    &-center
      width: 593px
      height: 160px
      background-color: $c-darkred
      border-radius: 8px
      box-sizing: border-box
      display: flex
      justify-content: space-between
      z-index: 10
      +drop-shadow

      .schaffe-ich-room__challenge--tablet &
        flex-direction: column
        height: 246px
        width: 280px
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0

    &-circle
      position: absolute
      width: 80px
      height: 80px
      overflow: visible
      background-color: $c-white
      +drop-shadow
      border-radius: 50%
      top: 0
      left: 50%
      transform: translate(-50%, -50%)
      display: flex
      //justify-content: center
      align-items: center

      .lottie__uncontrolled
        width: 80px
        height: 66px
        left: 50%
        transform: translateX(-50%)
        position: relative

        & > div
          overflow: visible !important

    &-current, &-current-goal
      display: flex
      flex-direction: column
      align-items: center
      width: calc(50% - 10px)
      height: stretch
      //Safari fix
      height: 100%
      background-color: $c-white
      border-top-left-radius: 8px
      border-bottom-left-radius: 8px
      position: relative
      box-sizing: border-box
      padding: 60px 0 14px

      .schaffe-ich-room__challenge--tablet &
        width: 100%
        width: stretch
        height: 127px
        border-bottom-left-radius: 0
        border-top-right-radius: 8px
        padding: 40px 0 14px

      & > h2
        +fontsize(48px, 48px)
        font-weight: bold
        +transition

        .schaffe-ich-room__challenge--tablet &
          +fontsize(42px, 42px)
          +transition

      & > h4
        .schaffe-ich-room__challenge--tablet &
          +fontsize(16px, 18px)

      &-triangle
        position: absolute
        right: -10px
        top: 0
        bottom: 0
        border-style: solid
        border-width: 80px 0 80px 10px
        border-color: transparent transparent transparent $c-white

        .schaffe-ich-room__challenge--tablet &
          right: 0
          top: auto
          bottom: -10px
          border-width: 10px 140px 0 140px
          border-color: $c-white transparent transparent transparent

    &-current .schaffe-ich-room__challenge-circle:first-of-type
      position: absolute

      .schaffe-ich-room__challenge--tablet &
        right: auto
        left: 0
        top: 0
        transform: translate(35%, -50%)


      .lottie__uncontrolled
        min-width: 145px

        div > svg
          position: relative
          left: 6px
          top: 2px
          width: 145px !important

    &-current .schaffe-ich-room__challenge-circle:nth-of-type(2)
      right: 0

      .schaffe-ich-room__challenge--tablet &
        left: auto
        right: 0
        top: 0
        transform: translate(-35%, -50%)

      .lottie__uncontrolled div
        & > svg
          position: relative
          top: -2px

    &-current-goal
      color: $c-white
      background-color: transparent

      .schaffe-ich-room__challenge--tablet &
        padding: 14px 0 14px
        height: 109px
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0

      .lottie__uncontrolled div
        & > svg
          position: relative
          top: -2px

    &-win
      border-radius: 0 8px 8px 0

  &__bet

    & > h1
      +fontsize(42px, 42px)
      +mq($from: mobile)
        +fontsize(64px, 64px)

    &-banderole
      display: flex
      flex-direction: column
      color: $c-white
      padding: 20px 0
      margin-top: 10px
      background-color: $c-grey

      +mq($from: tablet)
        margin-top: 20px
        +transition

      .schaffe-ich-room__bet--selected &
        background-color: $c-darkred

      & > h2
        font-weight: bold
        +fontsize(42px, 42px)

        +mq($from: mobile)
          +fontsize(48px, 46px)

    &-action
      margin-top: 20px
      display: flex
      align-items: center
      justify-content: center
      text-align: left
      +mq($from: tablet)
        margin-top: 40px
        +transition

    &-info
      margin-left: 10px
      +mq($until: mobile)
        & > h3
          +fontsize(16px, 22px)

    &-circle
      border-radius: 50%
      border: 3px solid $c-black
      cursor: pointer
      box-sizing: border-box
      width: 48px
      height: 48px

    &-circle-ticked
      height: 48px

      .icon
        +icon-size(48px)

  &__box
    border-radius: 8px
    display: flex
    flex-direction: column
    color: $c-black
    box-sizing: border-box
    align-items: center
    background-color: $c-white
    //width: stretch
    position: relative
    z-index: 20
    margin-top: 40px
    text-align: center
    padding-top: 60px
    width: stretch
    max-width: 332px
    height: 348px
    +transition

    +mq($from: mobile)
      max-width: none
      width: 332px
      height: 348px
    +mq($from: tablet)
      padding-top: 70px
      width: 380px
      height: 408px

    &--action
      background-color: $c-white
      border-radius: 50%
      width: 46px
      height: 46px
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      bottom: 0
      transform: translateY(50%)
      cursor: pointer
      +drop-shadow

      .icon
        +icon-size(30px)

      .icon--close
        +icon-size(20px)

    &--wrapper
      display: flex
      flex-direction: column
      align-items: center
      position: relative
      margin: 0 0 20px 0
      +mq($from: mobile)
        margin: 10px

      & > h2
        margin-bottom: 20px
        color: $c-white
        font-weight: bold
        +mq($until: tablet)
          +fontsize(26px, 32px)

    &--safe .dotted-circle
      transform: translate(-50%, -50%) rotate(180deg)

      & > .jackpot-counter__icon
        transform: rotate(-180deg)

    &-header
      display: flex
      width: stretch
      margin-bottom: 25px

      div
        display: flex
        justify-content: flex-start
        align-items: center
        flex: 50 50
        padding: 0 0 0 10px

        +mq($from: mobile)
          h4
            +fontsize(24px, 1.16)
            +transition


    +mq($until: desktop)
      &:not(:last-of-type)
        margin-bottom: 20px

    & > h3
      margin-bottom: 20px
      text-align: center

      +mq($from: mobile)
        text-align: left

    &-container
      display: flex
      flex-direction: column
      width: stretch

      &:not(:last-of-type)
        margin-bottom: 20px

      .jackpot-counter__icon .icon
        top: -4px
        +icon-size(48px)
        +icon-color($c-brightgrey)

      &--list
        width: stretch
        flex-direction: column

    &-wrapper
      display: flex
      margin-bottom: 20px
      +transition

      &:not(:last-of-type)
        margin-right: 20px

      .counter
        width: 33px
        height: 48px

        &__value
          +fontsize(33px, 33px)

    &-type
      justify-content: center
      display: flex
      +transition

    & > a
      margin-top: 40px

  &__actions
    margin-top: 40px
    display: flex
    width: stretch
    justify-content: center

