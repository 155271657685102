@import "../../../node_modules/sass-mq/mq"

// based on https://bulma.io/documentation/modifiers/responsive-helpers/#hide
$mq-breakpoints: (phone: 480px, phoneMobile: 700px, mobile: 768px, tabletMobile: 900px, tablet: 1024px, desktop: 1215px, widescreen: 1407px)

$mq-static-breakpoint: tablet

// force correct mqpacker order
html
  +mq($until: widescreen)
    margin: 0
  +mq($until: desktop)
    margin: 0
  +mq($until: tablet)
    margin: 0
  +mq($until: mobile)
    margin: 0
  +mq($until: phone)
    margin: 0