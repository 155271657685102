@import "~flatpickr/dist/themes/light.css"


.flatpickr-calendar

  .flatpickr-innerContainer
    border-bottom-right-radius: 5px
    border-bottom-left-radius: 5px

  .flatpickr-month, .flatpickr-weekdays,
  .flatpickr-weekday, .flatpickr-monthDropdown-months, .numInputWrapper:hover
    background: $c-white !important

  .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg
    fill: $c-turquoise

  div.dayContainer

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange
      background: $c-turquoise
      border-color: $c-turquoise
      color: $c-black

    .flatpickr-day.inRange
      background: rgba($c-turquoise, .5)
      //border-color: rgba($c-turquoise, .5)
      box-shadow: -5px 0 0 rgba($c-turquoise, .3), 5px 0 0 rgba($c-turquoise, .3)
      border: none

    .flatpickr-day.startRange
      box-shadow: 5px 0 0 rgba($c-turquoise, .3)

    .flatpickr-day.endRange
      box-shadow: -5px 0 0 rgba($c-turquoise, .3)

    .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))
      box-shadow: -10px 0 0 $c-turquoise