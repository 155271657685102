.login
  
  &__step-indicator
    align-self: flex-start
  
  &__background-video
    position: absolute
    left: 0
    top: 0
    right: 0
    object-fit: cover
  
  &__logo
    width: 180px
    height: 80px
    box-shadow: 0 2px 8px rgba($c-black, .16)
    display: block
    margin-bottom: 80px
    position: absolute
  
  &__wrapper
    height: 100%
    max-height: 900px
    background-color: $c-white
    
    +mq($from: mobile)
      margin: auto 0
  
  &__info-container
    max-height: 536px
    overflow-y: auto
    
    .login__columns--wrapper--step3 &
      max-height: 365px
  
  
  &__row + .login__row
    margin-top: 30px
    +mq($from: mobile)
      margin-top: 20px
  
  &__row
    width: 100%
    display: flex
    flex-direction: column
    
    .login__col
      box-sizing: border-box
      width: 100%
      
      +mq($from: tablet)
        padding-left: 5px
        padding-right: 5px
        width: 50%
      
      & + .login__col
        margin-top: 30px
        
        +mq($from: mobile)
          margin-top: 20px
        +mq($from: tablet)
          margin-top: 0
    
    +mq($from: tablet)
      flex-direction: row
      
      .textfield + .textfield
        margin-top: 0
    
    .textfield + .textfield
      margin-top: 20px
    
    .textfield-group
      margin-top: 0
      
      div.textfield:first-of-type
        flex: 2
      
      div.textfield:last-of-type
        flex: 3
    
    .textfield-group--street
      
      div.textfield:first-of-type
        flex: 4
      
      div.textfield:last-of-type
        flex: 2
  
  &__container
    
    &--left
      height: 100%
      padding-left: 30px
      position: relative
      overflow: hidden
      
      +mq($from: mobile)
        padding-left: 60px
      +mq($from: desktop)
        padding-left: 130px
      
      .logo
        margin-bottom: 80px
      
      .login__text-container
        position: absolute
        top: 160px
        padding-right: 20px
      
      
      h1
        word-break: break-word
      
      h2
        //position: absolute
        //top: 272px
        +transition
        
        +mq($from: tablet)
          //top: 332px
          +transition
      
      h1, h2
        color: $c-white
      
      .login__action--mobile
        display: block
        
        +mq($from: mobile)
          display: none
    
    &--right
      display: flex
      flex-direction: column
      height: 100%
      position: relative
      justify-content: center
      
      h2
        +fontsize(32px, 1.375)
        margin-bottom: 20px
        text-align: left
        align-self: flex-start
        word-break: break-word
        
        +mq($from: mobile)
          margin-bottom: 40px
      
      +mq($until: phone)
        .reset-form + .login__help-text
          padding-top: 90px
      
      .reset-form
        display: flex
        flex-direction: column
        
        +mq($from: mobile)
          margin-bottom: 200px
        +mq($until: mobile)
          padding-bottom: 90px
        
        .textfield
          padding-bottom: 60px
          
          +mq($until: mobile)
            padding-bottom: 30px
      
      .textfield
        margin-bottom: 0
      
      .login-form, .reset-form, .new-pass-form
        
        .login__action
          display: block
          text-align: center
          
          .button
            width: 100%
            margin-top: 30px
            margin-bottom: 40px
            
            +mq($from: mobile)
              margin-top: 60px
        
        .textfield + .textfield
          margin-top: 30px
          +mq($from: mobile)
            margin-top: 40px
      
      
      .reset-success
        position: relative
        
        &__form .button
          width: 100%
        
        &__icon
          width: 80px
          height: 80px
          border-radius: 50%
          display: flex
          justify-content: center
          align-items: center
          background-color: $c-white
          margin: 0 auto 40px auto
          position: relative
          +drop-shadow
          
          .lottie__uncontrolled
            position: absolute
            left: 50%
            height: 60px
            width: 60px
            top: 0
            transform: translate(-32%, 2%)
          
          .icon
            +icon-size(44px)
      
      .login__help-text
        display: block
        
        a:hover
          color: $c-orange
          +transition
      
      .login__agb-text
        +fontsize(12px, 1.33)
      
      .login__error-text
        margin-bottom: 10px
        color: $c-notification-red
      
      .login__links
        margin-top: 60px
        margin-bottom: 40px
        display: block
        
        a
          text-decoration: underline
          color: $c-black
          +fontsize(12px, 1.33)
          
          &:not(:first-of-type)
            margin-left: 20px
          
          &:hover
            color: $c-orange
            +transition
      
      .login__columns
        position: relative
        
        &--wrapper--step3
          justify-content: space-between
        
        form
          display: flex
          justify-content: space-between
        
        &--left
          width: 280px
          margin-right: 20px
        
        &--right
          width: 280px
          
          .checkbox__wrapper
            display: block
            margin-top: 60px
            margin-bottom: 10px
            
            label
              padding-left: 4px
              +fontsize(12px, 1.33)
            
            & + .checkbox__wrapper
              display: block
              margin-top: 0
          
          .textfield-group
            
            & > div.textfield:first-of-type
              flex: 1
              margin-right: 10px
            
            & > div.textfield:last-of-type
              flex: 2
        
        .login__actions .button
          width: 380px
          margin-top: 40px
      
      form.reset-form
        
        .button + .button
          margin-left: 0
          margin-top: 10px
          
          +mq($from: mobile)
            margin-top: 20px
      
      form.new-pass-form
        .button
          margin-top: 40px
          margin-bottom: 20px
          width: 100%

.splitview.step--1, .splitview.step--3, .splitview.step--5
  position: relative
  
  & > .splitview--sidebar
    padding: 40px 20px 0 20px
    
    +mq($from: mobile)
      padding: 80px 60px 0 60px
    
    +mq($from: desktop)
      padding: 0 120px 0 100px
      margin-top: auto

.splitview.step--5 > .splitview--sidebar
  margin-top: 0

.splitview.step--2, .splitview.step--6, .splitview.step--7
  width: 100%
  position: relative
  
  +mq($from: tablet)
    width: 100%
  
  & > .splitview--sidebar
    padding: 40px 20px 0 20px
    
    +mq($from: mobile)
      padding: 80px 60px 0 60px
      flex: 4
    
    +mq($from: desktop)
      padding: 80px 120px 0 100px
  
  & > .splitview--main
    display: none
    +mq($from: tablet)
      display: block
  
  & .login__container--left
    
    +mq($from: mobile)
      padding-left: 20px
    +mq($from: desktop)
      padding-left: 60px
  
  & .login__container--right
    
    .login__columns
      
      .login__col--action
        +mq($until: tablet)
          display: flex
          justify-content: center
        
        .button
          max-width: 280px
          width: 100%
        
        &--single
          width: 100%
          display: flex
          
          .button
            max-width: 50%
            margin-left: auto
      
      &--left, &--right
        width: 50%
        +mq($from: tablet)
          width: 280px
      
      &--wrapper
        display: flex
        flex-direction: column
        width: 100%
      
      form
        flex-direction: column
        align-items: center
        
        +mq($from: tablet)
          justify-content: space-between
          align-items: flex-start
  
  .checkbox__container
    margin-top: 20px
    display: flex
    
    a:hover
      color: $c-orange

.splitview.step--6 .checkbox__container,
.splitview.step--7 .checkbox__container
  margin-top: 0

// Navbar Login Language Switcher
.login__wrapper
  position: relative

  .navbar__language-switch
    position: absolute
    top: 0
    right: 0
    z-index: 2
    color: $c-darkblue

    button
      color: $c-darkblue

    +mq($until: tablet)
      display: block

.login__columns--wrapper
  a
    text-decoration: underline
    display: inline-block

  .checkbox:checked + span:before
    top: -3px
    left: -5px

  .checkbox__container
    padding-left: 30px
    flex-direction: column

  .checkbox__checkmark
    position: absolute
    left: 0
    top: 0

.login__row-mandatory
  .login__col
    width: 50%