.navbar__language-switch--select
  .selectfield__selected
    background: none
    color: $c-white
    padding: 0
    line-height: inherit
    min-width: 55px
    min-height: auto
    border-radius: 0

    +mq($until: tablet)
      color: $c-blue
      background: $c-lightgrey
      padding: 3px 5px 0 5px

    .button
      background: transparent
      color: $c-white
      font-weight: normal
      width: auto

      &:hover,
      &:focus
        background: transparent
        transform: inherit
        width: auto !important

  .selectfield__wrapper .icon svg use
    fill: $c-white

    +mq($until: tablet)
      fill: $c-blue

  .selectfield__wrapper .icon
    width: 15px

  .selectfield__arrow_active .selectfield__selected
    box-shadow: inherit
    border: none
    position: relative
    min-height: auto

  .selectfield__items
    top: 41px

  .selectfield__items div
    display: block
    padding: 0
    min-height: auto

  .button
    height: auto
    width: 100%
    border-radius: 0
    line-height: inherit

    &:hover,
    &:focus
      width: 100% !important
      background-color: $c-lightgrey

    &:last-of-type
      margin: 0 !important

  .selectfield__items div:hover,
  .selectfield__selected:hover
    background-color: transparent

.login__wrapper
  .navbar__language-switch
    margin: 15px 15px 0 0

  .navbar__language-switch--select .selectfield__selected
    color: $c-blue
    margin-right: 0

  .navbar__language-switch--select .selectfield__wrapper .icon svg use
    fill: $c-blue

  .navbar__language-switch--select .selectfield__items
    top: 30px

.light-mode
  .navbar__language-switch--select
    .selectfield__items
      .button--primary
        background-color: $c-white


