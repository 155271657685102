.salespush-performance

  &__section
    padding: 64px 10px 20px 10px
    display: flex
    flex-direction: column
    background-color: $c-darkblue
    position: relative

    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      transform: translate(-50%, -50%)

  &__content
    display: flex
    flex-direction: column

    +mq($from: tablet)
      justify-content: center
      align-items: center

    +mq($from: desktop)
      flex-direction: row
      flex-wrap: wrap
      align-items: stretch

  &__loader
    height: 40px
    max-width: 384px
    width: 100%
    border-radius: 32px
    background-color: $c-darkblue
    overflow: hidden
    margin: 0 auto 20px auto
    +transition

    +mq($from: mobile)
      margin: 0
    +mq($from: desktop)
      max-width: 226px
      .salesattack &
        max-width: none

    &--second
      background-color: $c-orange

    &-indicator
      display: block
      height: 100%
      background-color: $c-turquoise
      border-right: 2.5px solid $c-darkblue
      width: 0%

  &__item
    display: flex
    height: 60px
    min-height: 60px
    min-width: 480px
    width: 100%
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    border-radius: 8px
    justify-content: space-between
    align-items: center
    padding: 20px 40px
    box-sizing: border-box

    & > span
      width: 33%

      &:nth-of-type(2)
        text-align: center

      &:last-of-type
        text-align: right

    &--current
      background: $c-orange
      color: $c-black
      font-weight: bold

    &:not(:last-of-type)
      margin-bottom: 10px

  &__wrapper
    height: 200px

    &--scroll .salespush-performance__item
      min-width: 460px
      margin-right: 8px
  //max-width: 460px

  &__box
    border-radius: 8px
    display: flex
    flex-direction: column
    width: 100%
    color: $c-white
    padding: 20px 10px
    box-sizing: border-box
    align-items: center
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

    +mq($from: mobile)
      align-items: flex-start
      padding: 20px
    +mq($from: tablet)
      //max-width: 480px
      max-width: 680px

    &--list
      background: none
      overflow-x: auto
      padding: 20px 0

      .salesattack &
        padding-top: 0

        h3
          margin-bottom: 20px

    &--timer
      height: auto
      max-height: 320px

      +mq($from: tablet)
        max-width: 480px
      +mq($from: desktop)
        margin-right: 20px

      & > span
        display: flex
        justify-content: center
        width: 100%

        +mq($from: desktop)
          margin-top: 40px

        .schaffeich &
          margin-top: 0

      .progress-circle
        font-size: 86px

        +mq($from: mobile)
          font-size: 120px

        & > span:first-of-type
          +fontsize(39px, 39px)

          +mq($from: mobile)
            +fontsize(54px, 54px)

    &-header
      display: flex
      justify-content: space-between
      width: 100%
      margin-bottom: 25px
      min-width: 480px

      div
        display: flex
        justify-content: flex-start
        align-items: center

        & > h3
          margin-bottom: 0 !important

        & > span
          width: 46px
          height: 46px
          border-radius: 50%
          background-color: $c-blue
          display: flex
          justify-content: center
          align-items: center
          margin-right: 10px

          .icon
            +icon-color($c-white)
            +icon-size(24px)

    &--percent
      padding: 20px
      display: block

      +mq($from: mobile)
        .salespush-performance__box-container
          display: flex
          flex-direction: column

          & > div
            align-items: center
            display: flex
            width: 100%
            margin-bottom: 20px

      +mq($from: tablet)
        max-width: 480px
      +mq($from: desktop)
        margin-right: 20px

    &:not(:last-of-type)
      margin-bottom: 20px

    & > h3
      margin-bottom: 20px
      text-align: center

      +mq($from: mobile)
        text-align: left

    &-percent
      display: block
      text-align: center
      +fontsize(56px, 56px)
      font-weight: bold
      color: $c-orange

      +mq($from: mobile)
        margin-left: 20px

    &-container
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 100%
      overflow-x: auto

      .neukunden-performance__congrats
        margin: 0 auto 20px

        +mq($from: mobile)
          margin: 0 0 0 20px

      & > p
        text-align: center

      &--list
        width: 100%
        flex-direction: column

    &-wrapper
      display: flex
      margin-bottom: 20px
      +transition

      .counter
        width: 33px
        height: 48px

        &-spawner
          justify-content: flex-end

        &__value
          +fontsize(33px, 1.28)

    &-type
      margin-left: 10px
      white-space: nowrap
      +transition

      +mq($from: mobile)
        margin-left: 20px
        +transition

  &__actions
    margin-top: 40px
    display: flex
    width: 100%
    justify-content: center

.salespush-performance__box--full,
.salespush-performance__box-container.salespush-performance__box-container--list.salespush-performance__box-container--list
  overflow: inherit
  height: auto

  +mq($until: desktop)
    overflow: auto

.salespush-performance__box.salespush-performance__box--timer.salespush-performance__box--timer-full
  height: 360px
  max-height: inherit

  +mq($until: desktop)
    height: auto

  > span
    margin-top: 55px

    +mq($until: desktop)
      margin-top: 20px