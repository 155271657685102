.jackpot-counter-navbar
  color: $c-white
  display: flex
  align-items: center
  justify-content: center

  @media screen and (max-width: 1150px)
    transform: translate(0, 53px)
    position: absolute
    background: black
    padding: 11px 50px 12px 20px
    right: 169px
    border-bottom-left-radius: 8px

  @media screen and (max-width: 800px)
    padding-right: 35px
    padding-left: 14px

  @media screen and (max-width: 767px)
    padding: 10px 0
    transform: translate(0, 46px)
    border-bottom-right-radius: 8px
    border-bottom-left-radius: 8px
    right: 0
    width: 100%

  @media screen and (max-width: 500px)
    flex-wrap: wrap

  h6
    margin-right: 10px

    @media screen and (max-width: 500px)
      flex: 0 0 100%
      max-width: 100%
      margin: 20px 0 10px
      text-align: center

  .counter
    height: 25px
    width: 25px

  .counter__value
    font-size: 20px
    background-color: $c-white
    display: block
    padding: 0 7px 0
    line-height: 1.4
    box-sizing: inherit

  .counter--highlight .counter__value
    color: $c-black

  .counter-spawner .icon svg use
    fill: $c-white

  .counter-spawner
    .icon__inner
      position: absolute
      bottom: 0
      width: 8px
      left: auto
      padding: 4px 0

.pc .jackpot-counter-navbar .counter__value
  line-height: 1.1