.gloria-rewards-app
  .header
    height: 320px

  .gloria-rewards
    // Shop Header
    &__shop-header
      background-image: url("/images/app/gloria-rewards/img--heroslider-anim-bg.jpg")
      background-position: center
      height: 180px
      padding: 140px 130px 0

      h1, h2
        position: relative
        left: 100px
        font-weight: normal
        color: $c-white
        text-transform: uppercase

      h1
        font-size: 76px

      .app-header__icon-logo
        width: 60px
        height: 60px
        background-color: $c-black
        border: 1px solid $c-white
        border-radius: 15px
        padding: 6px 10px 10px 10px
        position: absolute
        left: auto
        top: auto

        .icon
          width: 60px
          height: 60px

          svg use 
            fill: $c-white

  .shop
    // Shop Settings
    .selectfield__selected,
    .textfield__input,
    .product__image,
    .product__upper,
    .product,
    .selectfield__items,
    .product__content,
    .selectfield__items div
      border-radius: 0

    .product__title
      padding: 20px
      width: calc(100% - 40px)
      text-align: center
      font-weight: normal

    .product__price--shop
      text-align: center
      width: 100%
      padding: 0
      margin: 0 0 20px
      color: $c-black
      font-family: 'Titillium', sans-serif
      font-weight: bold

    .button-wishlist--like
      background-color: rgba($c-brightgrey, 0.8)

      .icon
        svg use
          fill: $c-black

    .button-wishlist--active
      background-color: $c-white

    .filterbar .button
      border: 1px solid $c-black
      background-color: $c-white
      color: $c-black

      .icon svg use
        fill: $c-black

      &:focus, &:hover
        border: 1px solid $c-white
        background-color: $c-black
        color: $c-white

        .icon svg use
          fill: $c-white

  .product-view
    margin-top: 100px

    // Productview Settings
    .product-view__image,
    .product-view__image-big,
    .details__price,
    .details__variant-container .image,
    .selectfield__selected,
    .selectfield__items div:hover, 
    .selectfield__selected:hover
      border-radius: 0

    .details__price
      background-color: $c-darkgrey

    .details__actions--right .button, 
    .details__actions--right--mobile .button
      border: 1px solid $c-black
      background-color: $c-white
      color: $c-black

      .icon svg use
        fill: $c-black

      &:focus, &:hover
        border: 1px solid $c-white
        background-color: $c-black
        color: $c-white

        .icon svg use
          fill: $c-white

    .button-wishlist--like
      background-color: transparent
      border: 1px solid $c-black
      
      .icon svg use
        fill: $c-black
    
    .button-wishlist--active,
    .button-wishlist--like:hover
      background-color: $c-black
        
      .icon svg use
        fill: $c-white

  .card-slider__wrapper
    .product
      min-width: 100%
      max-width: 100%
      box-sizing: border-box

    .product__upper
      border-radius: 0

    .product__image
      max-width: 100%
      border-radius: 0

    .product__actions
      right: 10px

      .button-wishlist
        background-color: rgba($c-brightgrey, 0.8)
        border-color: transparent

        .icon svg use
          fill: $c-black

      .button-wishlist--active
        background-color: $c-white
    
    .product__price
      width: 100%
      border-radius: 0
      background-color: $c-darkgrey

    .product__content
      background-color: $c-greybg
      border-radius: 0

    .product__title
      font-weight: normal
      width: 350px
      font-size: 18px

    .button--secondary
      border-color: $c-black

      &:hover, &:focus
        border-color: $c-white
        background-color: $c-black
        color: $c-white
    