.chat-support
  position: absolute
  bottom: 0
  right: 0

  @media screen and (max-height: 700px)
    display: none

// Bubble
.chat-support__bubble
  position: fixed
  bottom: 40px
  right: 40px
  width: 60px
  height: 60px
  background-color: $c-white
  border-radius: 50%
  box-shadow: 0 0 13px rgba($c-black, 0.2)
  box-sizing: border-box
  padding: 10px
  z-index: 49
  cursor: pointer

  img
    max-width: 100%
    display: block
    width: 100%
    height: auto

.chat-support__new-message
  width: 10px
  height: 10px
  position: absolute
  right: 3px
  top: 5px
  background-color: $c-darkred
  z-index: 2
  border-radius: 50%

// Window
.chat-support__window
  position: fixed
  bottom: 120px
  right: 40px
  width: 450px
  height: 550px
  box-shadow: 0 0 13px rgba($c-black, 0.2)
  box-sizing: border-box
  z-index: 61
  border-radius: 10px
  overflow: hidden
  display: none

  +mq($until: tablet)
    width: 300px

.chat-support__window--active
  display: block

.chat-support__head
  width: 100%
  height: 50px
  background-color: $c-darkblue
  color: $c-white
  box-sizing: border-box
  padding: 18px
  font-weight: bold
  display: flex
  align-items: center

  +mq($until: tablet)
    padding: 0 13px
    font-size: 14px

.chat-support__body
  width: 100%
  height: 410px
  overflow-x: hidden
  overflow-y: auto
  box-sizing: border-box
  padding: 25px 15px 25px
  background-color: $c-white

.chat-support__message
  width: 100%
  display: flex
  justify-content: flex-start
  margin-bottom: 20px

  &:hover
    .chat-support__reactions
      display: flex

.chat-support__message--user
  justify-content: flex-end

  .chat-support__avatar
    order: 1
    margin-right: 0
    margin-left: 15px

.chat-support__message--new
  .chat-support__message-send
    border: 1px solid $c-darkred

.chat-support__avatar
  flex: 0 0 40px
  max-width: 40px
  background-color: $c-white
  margin-right: 15px
  height: 40px
  position: relative
  overflow: hidden
  box-sizing: border-box

  img
    max-width: 100%
    display: block
    width: 100%
    height: 100%
    border-radius: 50%
    position: absolute
    top: 0
    left: 0

.chat-support__message-send
  box-sizing: border-box
  padding: 10px 15px
  background-color: $c-lightgrey
  border-radius: 5px
  position: relative
  min-width: 155px

  small
    display: block
    font-size: 12px
    margin-top: 5px
    color: $c-grey

.chat-support__footer
  width: 100%
  height: 50px
  display: flex
  position: relative

  form
    width: 100%
    height: 50px
    display: flex
    position: relative

  input 
    height: 100%
    border: none
    border: 1px solid $c-brightgrey
    flex: 0 0 80%
    max-width: 80%
    padding: 0 20px 0 55px
    box-sizing: border-box
    font-size: 16px
    border-bottom-left-radius: 10px

    &:focus,
    &:active
      border: 1px solid $c-brightgrey !important

  button
    background-color: $c-darkblue
    height: 100%
    flex: 0 0 20%
    max-width: 20%
    color: $c-white
    border: none
    text-align: center
    cursor: pointer
    box-sizing: border-box
    padding-top: 8px

    .icon
      max-width: 25px

    svg use
      fill: $c-white

// Status
.chat-support__status
  position: absolute
  top: 5px
  right: 5px
  width: 5px
  height: 5px
  border-radius: 50%
  background-color: $c-brightgrey

.chat-support__status--read
  background-color: green

// Reactions
.chat-support__reactions
  background-color: $c-lightgrey
  position: absolute
  bottom: -12px
  left: 50%
  transform: translateX(-50%)
  display: flex
  box-shadow: 0 0 5px rgba($c-black, 0.2)
  box-sizing: border-box
  padding: 5px 5px 0
  border: 1px solid $c-brightgrey
  z-index: 2
  border-radius: 5px
  display: none

  .icon
    max-width: 20px
    max-height: 20px

.chat-support__emojis,
.chat-support__settings
  margin: 0 4px
  cursor: pointer

.chat-support__emojis-selection
  background-color: $c-lightgrey
  position: absolute
  top: -28px
  left: -50px
  box-shadow: 0 0 5px rgba($c-black, 0.2)
  box-sizing: border-box
  padding: 0 5px
  border: 1px solid $c-brightgrey
  border-radius: 5px
  display: none

  ul
    display: flex
    margin: 0
    padding: 0

    li
      margin: 0 5px
      cursor: pointer

.chat-support__emojis-selection--active
  display: block

.chat-support__footer-emojis
  position: absolute
  top: 10px
  left: 15px
  z-index: 1

  .icon
    max-width: 25px

.chat-support__emoji-button
  cursor: pointer

.chat-support__emoji-list
  position: absolute
  bottom: 120%
  left: 0
  background-color: $c-white
  box-shadow: 0 0 5px rgba($c-black, 0.2)
  width: 200px
  box-sizing: border-box
  padding: 5px
  border-radius: 5px
  display: none

  ul
    list-style: none
    margin: 0
    padding: 0

    li
      display: inline-block
      margin: 5px
      cursor: pointer

.chat-support__emoji-list--active
  display: block

.chat-support__mention
  width: 100%

  input
    background-color: $c-white !important
    flex: 0 0 100%
    max-width: 100%

.chat-support__mention__suggestions
  width: 100%
  margin: 0 !important
  top: auto !important
  bottom: 100%
  background-color: $c-white
  box-shadow: 0 0 5px rgba($c-black, 0.2)
  width: 100%
  box-sizing: border-box
  padding: 0 5px
  border-radius: 5px

.chat-support__mention-person
  display: flex
  align-items: center
  transition: all 0.3s ease-in-out
  cursor: pointer
  margin: 5px 0
  padding: 5px
  box-sizing: border-box

  &:hover
    background-color: $c-lightgrey

.chat-support__mention-person-avatar
  max-width: 25px
  flex: 0 0 25px

  img
    border-radius: 50%
    display: block
    max-width: 100%

.chat-support__mention-person-name
  max-width: calc(100% - 25px)
  flex: 0 0 calc(100% - 25px)
  padding-left: 10px
  padding-top: 3px
  box-sizing: border-box

.chat-support__message-reaction
  position: absolute
  bottom: -9px
  left: 5px
  display: flex
  align-items: center
  font-size: 12px
  width: 100%

.chat-support__message-reaction-wrap
  margin: 0 1px

  > div
    display: flex
    justify-content: center
    align-items: center

.chat-support__message-reaction-num
  margin-left: 0
  margin-right: 5px

.chat-support__user-select
  width: 100%
  height: 40px
  background-color: $c-white

  input
    &:focus
      border: none !important

  .selectfield__selected
    width: 100%
    min-height: 50px
    line-height: inherit
    border-bottom: 1px solid $c-brightgrey

.css-1s2u09g-control,
.css-1pahdxg-control
  border-color: $c-brightgrey !important
  box-shadow: inherit !important

.css-1n7v3ny-option
  background-color: $c-lightgrey !important

.css-1s2u09g-control,
.css-1pahdxg-control
  border-top: 0 !important
  border-left: 0 !important
  border-right: 0 !important
  border-bottom: 1px solid $c-brightgrey !important
  border-radius: 0 !important
  