.news-item
  margin-top: 10px
  border-radius: 8px
  background-color: $c-white
  padding: 20px
  max-width: 780px
  width: 100%

  &__header
    display: flex
    align-items: center
    justify-content: flex-start
    cursor: pointer
    +transition

    .news-item--active &
      border-bottom: 1px solid $c-brightgrey
      padding-bottom: 20px
      +transition

    .icon
      pointer-events: none
      margin-right: 20px
      +icon-size(32px)
      +transition

      +mq($until: mobile)
        +icon-size(24px)
        margin-right: 10px
        +transition


    &-indicator
      width: 16px
      height: 16px
      border-radius: 50%
      background-color: $c-turquoise
      margin-right: 20px
      +transition

      .news-item--read &
        background-color: $c-white

      +mq($until: mobile)
        margin-right: 10px
        +transition

    &-content
      display: flex
      flex-direction: column

    &-label
      +fontsize(24px, 1.16)
      font-weight: bold
      +transition

      +mq($until: mobile)
        +fontsize(18px, 1.22)
        +transition

      .news-item--read &
        font-weight: normal

    .icon:last-of-type
      transform: rotate(90deg)
      +icon-size(24px)
      +transition
      margin-left: auto
      margin-right: 0

      .news-item--active &
        transform: rotate(-90deg)
        +transition

  &__content
    max-height: 0
    overflow: hidden
    display: flex
    flex-direction: column
    padding-left: 88px
    +transition

    +mq($until: mobile)
      padding-left: 60px

    h4
      padding-bottom: 20px

    .news-item--active &
      padding-top: 20px
      max-height: 30em
      padding-bottom: 40px
      overflow-y: auto
      +transition

    .checkbox__container
      //align-items: center
      +fontsize(16px, 1.5)

    .checkbox__container:first-of-type
      margin-top: 12px

