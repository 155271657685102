.logo
  width: 180px
  height: 80px
  background-color: $c-white
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2)

  img
    width: 100%

  &--shrink
    height: 70px