.content-page

  &__content
    padding: 110px 10px
    +transition
    +mq($from: mobile)
      padding: 110px 44px
    +mq($from: tablet)
      padding: 110px 60px
    +mq($from: desktop)
      padding: 110px 130px

  &__upper
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-bottom: 60px
    +transition

    +mq($from: mobile)
      padding: 0 44px
    +mq($from: tablet)
      padding: 0 100px
      flex-direction: row

  &__text
    max-width: 580px
    margin: 0 auto 60px auto
    +transition
    +mq($from: tablet)
      max-width: 580px
      margin: 0 80px 0 0
    +mq($from: desktop)
      margin-right: 120px

    & > h2
      margin-bottom: 40px

  &__sidebar
    width: 280px
    height: max-content
    background-color: $c-white
    border-radius: 8px
    padding: 58px 20px 20px
    box-sizing: border-box
    position: relative
    margin: 0 auto
    +drop-shadow

    +mq($from: tablet)
      margin: unset

    &-heading
      width: 200px
      height: 56px
      display: flex
      position: absolute
      top: -28px
      justify-content: center
      border-radius: 8px
      align-items: center
      +gradient-gold
      left: 50%
      transform: translateX(-50%)
      +transition
      +drop-shadow

      +mq($from: phone)
        transform: unset
        left: -28px

    &-benefit
      &:not(:last-of-type)
        margin-bottom: 22px

      & > span
        display: block
        font-weight: bold
        margin-bottom: 12px

  &__center
    display: flex
    margin: -10px
    flex-direction: column

    +mq($from: tablet)
      flex-direction: row

    & > span
      display: flex
      flex-direction: column

  &__image
    width: stretch
    height: auto
    object-fit: cover
    border-radius: 8px
    overflow: hidden
    margin: 10px
    +transition
    +mq($from: tablet)
      width: 480px
      height: 200px

    &--big
      width: stretch
      height: auto
      +transition
      +mq($from: tablet)
        width: 680px
        height: 420px

    .image, img
      width: 100%
      height: 100%
      object-fit: cover
      line-height: 0

  &__lower
    margin-top: 60px
    display: flex
    justify-content: center

    & > .content-page__text
      margin: 0

  &__center
