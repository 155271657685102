.news-content
  box-sizing: border-box
  width: 100%
  position: relative
  padding: 40px 0 60px

  +mq($until: tablet)
    padding: 40px 20px 60px

  h2
    text-align: center
    margin-bottom: 50px

  ul
    list-style: none
    margin: 0 0 40px
    padding: 0

    li
      margin-bottom: 20px

  &--empty
    text-align: center

  &__item
    padding: 20px
    border-radius: 8px
    display: flex
    flex-wrap: wrap
    position: relative
    border: 2px solid $c-lightgrey
    transition: all 0.3s ease-in-out

    &--new
      background-color: $c-lightgrey

      .news-content__content--full
        padding-top: 30px
    
  &__image
    flex: 0 0 30%
    max-width: 30%
    box-sizing: border-box

    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      margin-bottom: 30px

    img
      max-width: 100%
      border-radius: 8px

    cite
      display: block
      font-size: 12px
      margin-top: 5px

    &--empty
      flex: 0 0 0
      max-width: 0


  &__content
    flex: 0 0 70%
    max-width: 70%
    padding: 0 0 0 24px
    box-sizing: border-box

    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      padding: 0

    small
      font-size: 14px

    h3
      font-size: 26px
      margin-bottom: 5px
    
    p
      font-size: 18px
      margin-bottom: 10px

    button
      height: 42px
      line-height: 42px
      padding: 0 40px

    &--full
      flex: 0 0 100%
      max-width: 100%
      padding-left: 0   

    .editor
      white-space: normal

      i
        font-style: italic 

      h1
        font-size: 26px
        margin-bottom: 10px

      h2
        font-size: 24px
        text-align: left
        text-transform: none
        margin-bottom: 15px


  &__action
    text-align: right

  &__badge
    position: absolute
    top: -10px
    left: 25px
    background-color: #939393
    color: $c-white
    font-size: 24px
    font-weight: bold
    padding: 8px 18px 4px
    border-radius: 8px

  &__all-news
    width: 100%
    text-align: center

    button
      border-color: $c-darkblue

      &:hover
        border-color: $c-turquoise

.light-mode
  .news-content
    h2, small, h3, p
      color: $c-black

    &--empty
      color: $c-black

    &__item
      border-color: $c-lightgrey

      &--new
        background-color: $c-lightgrey

    &__badge
      background-color: #939393

.dark-mode
  .news-content
    h2, small, h3, p, cite, a
      color: $c-white

    &--empty
      color: $c-white

    &__item
      border-color: $c-blue

      button
        background-color: $c-blue

        &:hover
          background-color: $c-turquoise
          color: $c-darkblue

      &--new
        background-color: $c-blue

        button
          background-color: $c-darkblue

          &:hover
            background-color: $c-turquoise
            color: $c-darkblue

    &__badge
      background-color: $c-white
      color: $c-black

    &__all-news
      button
        color: $c-white
        border-color: $c-white

        &:hover
          border-color: $c-turquoise
          color: $c-darkblue
