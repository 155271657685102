.footer
  width: 100%
  max-width: 1440px
  height: 200px
  background-color: $c-darkblue
  display: flex
  flex-direction: column
  align-items: center

  +mq($until: mobile)
    height: 100%

  &__links
    margin-top: 40px

    +mq($until: mobile)
      margin-top: 15px

  &__links
    display: flex
    flex-wrap: wrap
    // flex-direction: row
    // justify-content: space-evenly

    +mq($until: mobile)
      display: block
      text-align: center

    a
      display: inline-block
      margin: 0 20px
      text-decoration: none
      color: $c-white
      +fontsize(16px, 1.5)
      border-bottom: 2px solid transparent
      +transition

      &:hover
        border-bottom: 2px solid $c-turquoise
        +transition

      +mq($until: mobile)
        display: block
        margin: 10px 0
