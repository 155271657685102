.selectfield
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  border: none
  outline: none
  display: none
  position: relative
  padding: 10px 20px
  width: 100%

  &__label
    color: $c-grey
    display: block
    margin-left: 20px
    margin-bottom: 8px
    +fontsize(12px, 1.33)

  &__container
    //margin-bottom: 12px

    &--inactive
      pointer-events: none
      cursor: unset

  &__container.selectfield__noLabel
    height: auto

    .selectfield__wrapper .icon
      top: 50%

  &__wrapper.selectfield__arrow_active

    .icon
      transform: translateY(-50%) rotate(-90deg)
      +transition

  &__wrapper
    position: relative

    .icon
      width: 20px
      height: 20px
      position: absolute
      right: 12px
      cursor: pointer
      pointer-events: none
      top: calc(50% + 12px)
      transform: translateY(-50%) rotate(90deg)

      +icon-color($c-black)
      +transition

      .selectfield__container--inactive &
        +icon-color($c-brightgrey)

  &:focus::-ms-value
    background-color: $c-lightgrey

  &::-ms-expand
    display: none

  &__items div, &__selected
    color: $c-black
    cursor: pointer
    background-color: $c-white
    min-height: 48px
    box-sizing: border-box
    vertical-align: middle
    +fontsize(18px, 38px)
    +transition
    display: flex
    align-items: center

    .selectfield__container--inactive &
      color: $c-brightgrey

    &:hover
      background-color: $c-lightgrey
      +transition

  &__selected
    border-radius: 8px
    padding-left: 20px
    line-height: 44px
    border-color: $c-lightgrey
    padding-right: 44px
    text-transform: capitalize

    form &
      background-color: $c-lightgrey

    .selectfield__arrow_active &
      border-bottom: 1px solid $c-lightgrey
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      +drop-shadow

  &__items
    position: absolute
    background-color: $c-white
    color: $c-black
    top: 100%
    left: 0
    right: 0
    z-index: 20
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    padding: 8px
    max-height: 20em
    overflow-y: auto
    +transition
    +drop-shadow

    &--hidden
      //max-height: 0
      //padding: 0
      display: none
      +transition

    &--hidden > div
      opacity: 0
      display: none

    div
      border-bottom: 1px solid $c-lightgrey
      border-radius: 8px
      padding-left: 12px

      &:last-of-type
        border-bottom: none
        border-bottom-left-radius: 8px
        border-bottom-right-radius: 8px
