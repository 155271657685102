.accordion
  //padding-top: 16px
  border-top: 1px solid $c-brightgrey
  margin-top: 20px

  & + .accordion
    margin-top: 0
    border-top: none

  &__header
    font-weight: bold
    display: flex
    justify-content: space-between
    align-items: center
    padding: 13px
    cursor: pointer

    .icon
      pointer-events: none
      +icon-size(18px)


  &__items
    max-height: 0
    overflow: hidden
    display: flex
    flex-direction: column
    border-bottom: 1px solid $c-brightgrey
    padding-left: 13px
    +transition

    .accordion--active > &
      max-height: 23em
      padding-bottom: 40px
      overflow-y: auto
      +transition

    .checkbox__container
      //align-items: center
      +fontsize(16px, 1.5)

    .checkbox__container:first-of-type
      margin-top: 12px

