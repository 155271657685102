.teamTrophy .app-header
  position: relative

  &__section
    background-color: $c-darkblue
    min-height: 312px
    height: 512px
    display: flex
    flex-direction: row
    background-image: url("/images/app/neukunden/img--neukunden-bg.jpg")
    background-position: bottom
    margin-bottom: 270px
    box-sizing: border-box
    position: relative
    +transition

    //white ellipse (desktop only)
    +mq($from: tablet)
      &:after
        content: url("/images/app/team-trophy/trophy-ground.svg")
        position: absolute
        bottom: -1px
        right: 130px
        line-height: 0
        width: 360px
        z-index: 5
        +transition

    .app-header__left h3
      display: block
      +mq($until: tablet)
        margin-top: 0

    .app-header__left p
      display: block
      max-width: 400px
      max-height: 0
      +mq($until: tablet)
        max-height: 0
        overflow: hidden
        margin: 0 auto

    &--open
      //height: 920px
      height: auto
      margin-bottom: 0
      +transition

      &:after
        width: 950px
        right: -100px
        bottom: -1px
        +transition

        +mq($from: desktop)
          right: 0

      .app-header__left p
        max-height: 390px
        +transition
        +mq($until: tablet)
          max-height: 480px

      .app-header__right

        +mq($from: tablet)
          right: 220px
          bottom: 32px
          transform: none
          +transition

        +mq($from: desktop)
          right: 320px

    video
      position: absolute
      z-index: 1
      left: 0
      right: 0
      top: 50%
      transform: translateY(-50%)
      object-fit: cover
      border-radius: 1px
      width: 100%
      height: 100%
      overflow: hidden

      .oldie &
        display: none

    +mq($until: tablet)
      height: 540px
      margin-bottom: 350px
      +transition

      &--open
        height: 960px
        +transition

    +mq($until: mobile)
      height: 412px

      &--open
        height: 880px
        +transition

    +mq($from: tablet)
      height: auto
      +transition

      &--open
        height: auto
        +transition

  &__box
    line-height: 0
    display: none
    margin-top: auto
    position: absolute
    right: 0
    bottom: 0
    width: 241px
    overflow: hidden
    z-index: 60
    +transition

    +mq($from: mobile)
      display: block

    +mq($from: tablet)
      left: 40%
      width: 204px
      height: 258px
      z-index: 20
      margin-bottom: -30px
      +transition

      .app-header__section--open &
        margin-bottom: 30px
        z-index: 1
        width: 359px
        height: 453px
        transform: translateX(10%)

    .image
      overflow: hidden
      +transition

      +mq($from: tablet)
        +transition

      img
        width: 100%
        height: 100%

  &__content
    color: $c-white
    padding: 20px 10px 40px 10px
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    z-index: 23
    +transition

    +mq($from: mobile)
      padding-left: 60px
      padding-right: 60px
      padding-top: 60px
      +transition

    +mq($from: tablet)
      flex-direction: row
      padding-left: 80px
      padding-top: 110px
      padding-right: 80px
      justify-content: space-between
      +transition

    +mq($from: desktop)
      padding-left: 130px
      padding-right: 130px

    & > p
      display: none

      +mq($from: tablet)
        display: block
        max-width: 480px

  &__actions
    display: none
    position: relative

    +mq($from: tablet)
      display: block
      max-width: 480px

      .app-header__section--open &
        display: flex
        flex-direction: column

      .button:first-of-type
        color: $c-white
        padding-left: 26px
        width: max-content
        margin-bottom: 20px
        align-self: flex-start

        .icon
          left: 0
          +icon-color($c-white)
          +icon-size(16px)

      .button:last-of-type
        max-width: 280px

        .app-header__section--open &
          display: block
          margin-left: 0

  &__actions--mobile
    display: block
    text-align: center
    z-index: 40
    position: relative
    transform: translateY(-350px)

    .button
      position: absolute
      top: 0
      left: 50%
      transform: translate(-50%, -50%)

    +mq($from: tablet)
      display: none !important

  &__heading
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: max-content

    +mq($from: tablet)
      min-width: 430px
      justify-content: flex-start
      align-items: flex-start
      z-index: 22
      margin-right: 10px

    div
      display: flex
      flex-direction: column
      align-items: center
      min-height: max-content

      +mq($from: tablet)
        flex-direction: row
        align-items: unset

      & > span
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 10px
        +transition

        +mq($from: mobile)
          margin-bottom: 20px

        .icon
          +icon-color($c-white)
          +icon-size(40px)

          +mq($from: mobile)
            +icon-size(80px)
            flex-shrink: 100
            margin-right: 20px
            +transition

          +mq($from: tablet)
            +icon-size(60px)

    h1
      text-transform: uppercase
      +mq($until: mobile)
        +fontsize(42px, 1.14)
        +transition
      +mq($from: mobile)
        +fontsize(86px, 0.9)

    h2
      text-align: center
      margin-bottom: 20px

      +mq($until: phone)
        +fontsize(18px, 1.22)
      +mq($from: tablet)
        text-align: left
        margin-left: 80px

  &__left
    display: flex
    flex-direction: column
    min-height: max-content

    h3
      display: none
      max-width: 480px
      width: stretch
      //margin-bottom: 20px

      .app-header__section--open &
        display: block

        +mq($until: tablet)
          margin: 40px auto 0 auto
          text-align: left

    p
      max-width: 400px
      //max-height: 277px
      display: none
      overflow: hidden
      +transition

      .app-header__section--open &
        display: block

        +mq($until: tablet)
          margin: 0 auto

      +mq($from: tablet)
        margin-bottom: 30px

      +mq($from: desktop)
        max-width: 480px


  &__right
    display: flex
    height: max-content
    align-items: flex-end
    left: auto
    position: absolute
    right: 50%
    bottom: 0
    transform: translateX(50%) translateY(85%)
    +transition

    +mq($until: tablet)
      bottom: -90px

.teamTrophy-counter
  width: 260px
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  background-color: $c-white
  display: flex
  flex-direction: column
  align-items: center
  padding: 19px
  padding-bottom: 10px
  position: relative
  color: $c-black
  box-sizing: border-box
  margin: 0 auto 50px auto
  z-index: 18
  +drop-shadow
  +transition

  +mq($from: mobile)
    width: 280px

  .app-header__section--open &
    +mq($from: tablet)
      margin: 230px auto 50px auto

  &__bank
    position: absolute
    bottom: 0
    left: 0
    width: 260px
    height: 50px
    display: flex
    flex-direction: column
    +gradient-blue-dark
    color: $c-white
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    justify-content: center
    align-items: center
    transform: translateY(100%)
    z-index: 12
    +drop-shadow
    +transition

    +mq($from: mobile)
      width: 280px

    h2
      font-size: 28px

  & .counter
    +mq($until: mobile)
      width: 29px
      height: 42px

    +mq($from: mobile)
      height: 60px
      width: 40px

    &__value
      +mq($until: mobile)
        font-size: 30px
      +mq($from: mobile)
        font-size: 44px

  //transparent trophy icon
  $trophyIconWidth: 230px

  &__icon
    align-items: center
    //display: flex
    justify-content: center
    position: absolute
    right: 210px
    bottom: 26px
    width: 178px
    height: 178px
    +transition

    .lottie__uncontrolled
      margin-bottom: -6px

    .app-header__section--open &
      +mq($from: tablet)
        top: auto
        bottom: 300px
        left: auto
        right: 242px
        transform: none
        width: $trophyIconWidth
        +transition
      +mq($from: desktop)
        right: 342px

    +mq($until: tablet)
      bottom: 0
      right: 50%
      transform: translateX(50%)
      height: 218px
      width: 218px

  & > h3
    margin-bottom: 10px
    +transition

    +mq($until: mobile)
      +fontsize(22px, 1.04)
      +transition

  &__wrapper
    display: flex
    position: relative

    .counter-spawner
      position: relative
      border: 6px solid $c-orange
      border-bottom-width: 28px
      background-color: $c-orange
      border-radius: 8px
      margin-bottom: 10px
      +transition

      +mq($from: mobile)
        border: 20px solid $c-orange
        border-right-width: 33px

    h5
      font-size: 18px
      position: absolute
      color: $c-white
      font-weight: bold
      top: auto
      bottom: 12px
      left: 50%
      transform: translateX(-50%)
      +transition

      +mq($from: mobile)
        margin-top: -16px
        transform: rotate(-90deg)
        bottom: auto
        right: -3px
        left: auto
        top: 50%


