.profile

  &-header

    &__section
      padding: 60px 130px
      background-color: $c-darkblue
      color: $c-white
      position: relative
      +transition

      +mq($until: tablet)
        padding: 20px 44px
        +transition

      +mq($until: phone)
        padding: 20px 10px
        +transition

      & > h2
        padding-top: 46px
        text-align: center
        padding-bottom: 40px

        +mq($until: phone)
          padding-bottom: 20px

    &__image
      display: block
      width: 132px
      height: 132px
      background-color: $c-white
      border-radius: 50%
      overflow: hidden
      border: 10px solid $c-white
      box-sizing: border-box
      +transition

      &-wrapper
        position: absolute
        left: 50%
        top: 0
        transform: translate(-50%, -50%)

      +mq($until: tablet)
        width: 100px
        height: 100px
        border: 5px solid $c-white
        +transition

      .image
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center

        img
          width: 100%
          height: auto
          object-fit: cover

    &__add-new
      position: absolute
      bottom: 0
      background-color: white
      border-radius: 50%
      z-index: 200
      right: 0
      width: 48px
      height: 48px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      +transition

      &:hover, &:focus
        transform: scale(1.1)

        .icon
          +icon-color($c-orange)
          +transition

      .icon
        +icon-size(24px)
        +transition

    &__content
      display: flex
      flex-direction: column

    &__actions
      display: flex
      justify-content: center

      .button
        max-width: 280px
        width: stretch

  &__personal-data, &__newsletter
    max-width: 820px
    width: stretch
    margin: 0 auto
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    border-radius: 8px
    padding: 20px
    box-sizing: border-box
    color: $c-white
    +drop-shadow

    label
      color: $c-white
      align-items: center

  &__newsletter
    padding: 15px 20px
    margin-top: 20px
    margin-bottom: 40px

    & > .checkbox__container
      margin: 0

