.modal-information
  max-width: 377px
  min-height: 300px
  height: max-content
  background-color: $c-white
  position: fixed
  //border: 2px solid $c-brightgrey
  border-radius: 8px
  box-sizing: border-box
  display: flex
  flex-direction: column
  top: 25%
  left: 50%
  transform: translate(-50%, -25%)
  z-index: 200
  +drop-shadow

  &--error
    padding-bottom: 40px

    h3
      color: $c-notification-red
      margin-bottom: 20px

    .button
      margin-top: 20px
  
  &--contracts
    // .lottie__uncontrolled:first-child
    //   display: none
    
    button
      margin-top: 20px

    .modal-information__upper
      padding-bottom: 20px

  &__upper
    flex-direction: column
    display: flex
    padding: 20px 20px 0 20px

  &__center
    display: flex
    justify-content: center
    align-items: center
    width: 80px
    height: 80px
    border-radius: 50%
    +drop-shadow
    margin: 20px auto 40px

  &__close
    align-self: flex-end
    cursor: pointer

    .icon
      +icon-size(18px)

  &__row
    display: flex
    flex-direction: column
    text-align: center
