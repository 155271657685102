.news
  position: relative
  padding: 0

  &__empty
    padding: 60px 40px 60px 40px

    +mq($until: mobile)
      padding: 40px 20px 40px 20px

  &__header
    width: 100px
    height: 100px
    border-radius: 50%
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, -50%)
    overflow: hidden
    +drop-shadow
    display: flex
    justify-content: center
    align-items: center
    z-index: 50
    background-color: $c-white
    +transition

    +mq($until: mobile)
      width: 80px
      height: 80px
      +transition

    .icon
      +icon-size(49px)
      +icon-color($c-darkred)
      +transition

      +mq($until: mobile)
        +icon-size(38px)
        +transition

    .lottie__uncontrolled
      width: 60px
      height: 60px
      +transition
      +mq($from: mobile)
        width: 90px
        height: 90px
        +transition

  &__content
    padding: 90px 130px
    background-color: $c-lightgrey
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 100px

    +mq($until: tablet)
      padding: 60px 44px
      +transition

    +mq($until: phone)
      padding: 60px 10px
      +transition
