.praemienclub-performance

  &__section
    +gradient-gold
    margin-bottom: 40px
    padding-top: 128px

    +mq($from: desktop)
      padding-top: 104px

    .app-header__actions--mobile button
      box-sizing: content-box

