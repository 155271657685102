h1
  +fontsize(32px, 1.31)
  font-weight: bold
  +transition

  +mq($from: mobile)
    +fontsize(48px, 1.08)

  +mq($from: tablet)
    +fontsize(86px, 1)
    +transition

h2
  +fontsize(32px, 1.25)
  +transition
  text-transform: uppercase

  +mq($until: phone)
    +fontsize(22px, 1.27)
    +transition

h2.product-headline
  text-transform: none
  font-weight: bold
  +fontsize(32px, 1.25)

h3
  +fontsize(24px, 1.16)
  font-weight: bold

h4
  +fontsize(18px, 1.22)
  font-weight: bold

h5
  +fontsize(16px, 1.375)
  font-weight: bold

p
  +fontsize(16px, 1.5)

p.infotext
  margin: 10px 0 0 20px
  font-weight: bold

  &.error
    color: $c-notification-red


