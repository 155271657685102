.banner-section
  width: 100%
  position: relative
  margin-bottom: 40px

  +mq($until: desktop)
    h1 
      font-size: 70px

  +mq($until: tablet)
    h1 
      font-size: 66px

  +mq($until: mobile)
    h1 
      font-size: 33px


  .banner-section-inner
    position: relative
    widht: 100%
    max-height: 310px
    overflow: hidden

    +mq($until: mobile)
      max-height: 220px

    .app-header__section
      h2
        padding: 0

      p, .app-header__actions
        display: none

      button
        color: $c-black
        padding: 0 40px

      .app-header__box
        max-width: 400px
        width: 100%
        height: auto
        left: auto
        right: 150px
        min-height: 100%
        top: 60px
        bottom: auto
    
    .schaffe-ich-room__timer-wrapper
      display: none

      h2
        text-align: left

      .salespush-performance__box--timer > span
        margin-top: 0
    
    .teamTrophy-counter__icon
      z-index: 1
      right: 340px
      bottom: auto
      top: 132px

    .teamTrophy-counter
      position: relative
      right: 100px

      +mq($until: tablet)
        right: 0
        margin-top: 50px

    .product-search__section
      padding-bottom: 40px

    .heros-place-header__section
      .app-header__box
        top: 20px
        right: 170px

    .casino-header__section
      video
        transform: inherit
        top: auto
        bottom: 230px

      .casino-room__slots-wrapper
        display: none
    
    .praemienjackpot-header__section
      .app-header__right
        display: none

      .app-header__box
        max-width: 230px
        top: 0
        right: 220px

    .private-header__section
      .app-header__right
        display: none

      .app-header__box
        max-width: 220px

    // Open State
    &--open
      max-height: inherit

      &:after
        display: none

      .app-header__section
        p, .app-header__actions
          display: block

        .app-header__box
          max-width: inherit
          width: inherit
          height: inherit
          left: inherit
          right: -160px
          bottom: inherit
          top: inherit
          min-height: inherit
          top: 100px
      
      .schaffe-ich-room__timer-wrapper
        display: flex
      
      .teamTrophy-counter__icon
        z-index: 1
        right: 250px
        top: 185px

      .heros-place-header__section
        .app-header__box
          max-width: 800px
          right: 170px
          top: 50px

      .neukunden-header__section
        .app-header__box
          width: 700px
          top: 180px
          right: 0

      .casino-header__section
        video
          transform: translateY(-50%)
          top: 50%
          bottom: auto

        .casino-room__slots-wrapper
          display: flex

      .praemienjackpot-header__section
        .app-header__right
          display: block

        .app-header__box
          right: 500px
          top: 210px
          max-width: 330px

      .private-header__section
        .app-header__right
          display: block

        .app-header__box
          max-width: 470px
          right: 350px
          top: 180px

  .button--secondary
    &.apps-open-close-btn
      margin: -25px auto 0
      display: block
      padding: 0 40px
      border-color: transparent !important
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
      position: relative
      z-index: 60

      .icon
        left: auto
        right: 20px
        width: 15px
        top: 11px
        transform: rotate(90deg)

      &--open
        .icon
          right: 10px
          top: 10px
          transform: rotate(-90deg)

.light-mode
  .banner-section

.dark-mode
  .banner-section-inner
    &:after
      width: 100%
      height: 150px
      content: ""
      position: absolute
      left: 0
      bottom: 0px
      background: linear-gradient(to bottom, rgba(15,15,49,0) 0%,rgba(15,15,49,1) 100%)   
      z-index: 20

  