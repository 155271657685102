.card-slider
  width: 100%
  //max-width: 1168px
  height: auto
  margin: 0 auto
  position: relative

  //.tns-item:first-of-type
    //margin-left: 6px

  //.tns-item:last-of-type
  //margin-right: 6px

  &__wrapper
    //max-width: 1294px
    margin: 0 auto
    display: flex
    flex-direction: column
    position: relative
    height: unset !important

    & > a
      margin: 40px auto 0 auto
      align-self: center

      .button--secondary
        padding: 0 20px

  &__header
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-bottom: 26px

  +mq($until: mobile)
    &__label
      text-align: center

  &__container
    width: 100%
    //max-width: 1294px
    display: flex
    flex-direction: row
    position: relative

  &__arrow
    height: 62px
    width: 48px
    top: 50%
    bottom: 50%
    cursor: pointer
    position: absolute
    z-index: 10
    //background-color: $c-darkgrey
    +icon-color($c-darkblue)

    +mq($until: desktop)
      display: none

    .icon
      pointer-events: none
      width: 27px
      height: 27px

    &--left
      left: -48px

      .icon
        transform: rotate(180deg)

    &--right
      right: -48px

    &--inactive
      //background: $c-brightgrey
      +icon-color($c-brightgrey)
