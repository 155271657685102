@keyframes fadeIn
  0%
    visibility: visible
    opacity: 0

  100%
    visibility: visible
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1

  99%
    opacity: 0

  100%
    opacity: 0
    visibility: hidden

.reward-categories
  position: absolute
  left: 0
  top: 60px
  max-height: 0
  overflow: hidden
  background-color: $c-white
  z-index: 95
  width: 100%
  +drop-shadow
  +transition
  box-sizing: border-box

  +mq($until: mobile)
    top: 48px
    position: fixed

  &--visible
    max-height: 700px
    +transition

    +mq($until: mobile)
      //opacity: 1
      overflow-y: auto
      height: 100%
      max-height: calc(100% - 88px)
      +transition

  &__container
    //padding: 60px 0 60px 130px
    padding: 60px 0 60px 0
    display: flex
    position: relative
    width: 100%
    +transition
    box-sizing: border-box

    +mq($until: tablet)
      padding: 60px 0 44px 44px
      +transition

    +mq($until: mobile)
      padding: 0 20px 0 20px
      flex-direction: column
      justify-content: space-between

  &__left
    display: flex
    z-index: 5
    background-color: $c-white
    flex-direction: column
    flex: 0 0 50%
    box-sizing: border-box
    padding-top: 60px
    margin-bottom: 40px
    max-width: 100%
    +transition

    +mq($from: mobile)
      padding-right: 20px
      padding-top: 0

    +mq($from: tablet)
      padding-right: 20px
      padding-left: 130px

    h2
      margin-bottom: 40px

      +mq($until: mobile)
        margin-bottom: 20px

    &--below
      //width: stretch
      left: -340px
      background-color: $c-white
      position: absolute
      z-index: 4
      height: 100%
      padding-right: 20px
      padding-left: 45px
      box-sizing: border-box
      pointer-events: none
      +transition

      &--back
        padding: 16px 16px 16px 0
        display: block
        width: 46px
        height: 46px
        box-sizing: border-box

        .icon
          transform: rotate(180deg)
          +icon-size(18px)

        +mq($from: mobile)
          display: none

      h2
        padding-top: 10px
        margin-bottom: 20px

        +mq($from: mobile)
          padding-top: 0
          margin-bottom: 40px

      &--visible
        pointer-events: all
        opacity: 1
        left: 48%
        min-width: calc(50% - 65px)
        max-width: calc(50% - 65px)
        height: calc(100% - 90px)
        overflow-y: auto
        +transition

        +mq($until: tablet)
          left: calc(50% + 22px)
          height: calc(100%)
          overflow-y: auto

        +mq($until: mobile)
          left: 0
          min-width: 100%
          max-width: 100%
          z-index: 200
          padding: 0 20px
          box-sizing: border-box


  &__categories, &__subcategories
    display: flex
    flex-wrap: wrap
    margin: -5px -10px
    width: 100%

    +mq($until: desktop)
      justify-content: center
      align-items: center

    +mq($until: mobile)
      margin: -5px

    & > .button, & > a
      margin: 5px 10px
      width: calc(50% - 20px)
      max-width: 220px
      min-width: 160px
      white-space: break-spaces
      padding: 0 10px
      min-height: 48px
      line-height: 1.23
      height: auto

      +mq($until: desktop)
        margin: 5px

      +mq($until: tablet)
        margin: 3px
        font-size: 14px

      & > .button
        width: 240px

      +mq($until: mobile)
        margin: 5px auto
        width: 48%
        min-width: 140px
        white-space: break-spaces
        padding: 0 10px
        min-height: 48px
        line-height: 1.23
        height: unset

        & > .button
          width: 100%

      +mq($until: phone)
        width: calc(50% - 10px)
        margin: 5px
        white-space: break-spaces
        padding: 0 10px
        min-width: 130px
        min-height: 48px
        line-height: 1.23
        height: unset

  &__right
    display: flex
    flex-direction: column
    z-index: 2
    flex: 0 0 50%
    max-width: 50%

    +mq($from: tablet)
      padding-left: 0
      padding-right: 20px

    +mq($until: mobile)
      margin-top: auto
      flex: unset
      flex: 0 0 100%
      max-width: 100%

  &__header
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 40px
    max-width: 600px

    h3
      margin-right: 10px

  &__notification-bubble
    background-color: $c-notification-red
    color: $c-white
    height: 30px
    width: 30px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center

  &__highlights
    display: flex
    flex-wrap: wrap
    margin: 0 -10px
    max-width: 620px
    max-height: 500px
    overflow-y: auto
    padding-bottom: 40px

    +mq($until: desktop)
      margin: 0 -5px

    +mq($until: mobile)
      flex-direction: row
      flex-wrap: nowrap
      overflow-y: hidden
      overflow-x: scroll
      max-width: unset
      padding-bottom: 0

    span
      margin: 10px
      text-decoration: none
      cursor: pointer
      box-sizing: border-box

      +mq($until: desktop)
        margin: 5px

      .image
        box-sizing: border-box
        border: 2px solid $c-lightgrey
        border-radius: 8px
        padding: 20px
        width: 180px
        height: 180px
        +transition

        +mq($until: desktop)
          width: 150px
          height: 150px

        &:hover, &:focus
          +drop-shadow
          +transition

      img
        width: 100%
        height: 100%
        object-fit: contain

    &-all
      background-color: $c-blue
      width: 180px
      height: 180px
      border-radius: 8px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 30px
      box-sizing: border-box
      +transition

      +mq($until: desktop)
        width: 150px
        height: 150px

      .icon
        +icon-size(48px)
        +icon-color($c-white)
        margin-bottom: 16px
        align-self: center

      &:hover, &:focus
        +drop-shadow
        +transition

      &-label
        text-decoration: none
        color: $c-white
        display: block
        text-align: center
        width: 100%

        +mq($until: desktop)
          font-size: 16px

  &__lower
    display: flex
    justify-content: flex-end
    max-width: 600px

    a
      text-decoration: none

  &__overlay
    width: 100%
    height: 100%
    position: fixed
    background-color: rgba(0, 0, 0, 0.8)
    top: 0
    left: 0
    right: 0
    bottom: 0
    pointer-events: all
    z-index: 50
    +transition
    visibility: hidden
    //animation: fadeOut 0.3s forwards

    &--visible
      display: block
      animation: fadeIn 0.3s forwards
      +transition

+mq($until: mobile)
  .navbar__category-button ~ .reward-categories
    top: 87px

+mq($from: mobile)
  .navbar__container--scroll ~ .reward-categories--visible
    position: sticky
    top: 0

  .navbar__container--scrolling-up ~ .reward-categories--visible
    top: 60px !important
    +transition

