.privacy
  padding-bottom: 90px
  max-width: 480px
  margin: 0

  h1
    padding-top: 80px
    padding-bottom: 60px

  h3
    padding-bottom: 20px
    padding-top: 40px


    &:first-of-type
      padding-top: 60px
