.textlink
  text-decoration: underline
  display: flex
  justify-content: flex-start
  align-items: center
  color: $c-black
  +transition
  +fontsize(16px, 1.5)

  &__wrapper
    display: flex

  &:hover, &:hover > .icon
    color: $c-orange
    +icon-color($c-orange)
    +transition

  .icon
    cursor: pointer
    transform: rotate(180deg)
    margin-right: 10px
    +icon-size(16px)
    +transition

    svg use
      +transition

  &--after .icon
    margin-left: 10px
    transform: unset


