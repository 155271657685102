.heros-ranking
  position: relative

  &__section
    background-color: $c-darkblue
    display: flex
    flex-direction: column
    box-sizing: border-box
    position: relative
    color: $c-white
    +transition

  &__header
    padding-top: 20px
    text-align: center

    +mq($from: mobile)
      padding-top: 45px

  &__content
    padding: 40px 10px
    color: $c-white
    z-index: 1
    display: block
    +transition

    +mq($from: mobile)
      padding: 60px
      +transition

    +mq($from: tablet)
      padding: 60px 80px
      +transition

  &__wrapper
    display: flex
    position: relative

    +mq($until: desktop)
      flex-direction: column
      align-items: center

  &__rank
    display: flex
    width: 128px
    height: 128px
    border: 8px solid $c-white
    background-color: $c-darkred
    justify-content: center
    align-items: center
    border-radius: 50%
    margin: 0 auto

    h1
      +fontsize(48px, 1.26)
      position: relative
      top: -5px

      @media (max-width: 1214px)
        font-size: 36px !important

    &--wrapper
      display: block
      position: absolute
      top: -45px
      left: 50%
      transform: translateX(-50%)

    &--name
      display: block
      height: 56px
      box-sizing: border-box
      padding: 10px 20px
      background-color: $c-orange
      border-radius: 8px
      margin-top: -45px
      z-index: 5
      text-transform: uppercase

      h3
        +fontsize(42px, 42px)

    &--label
      display: flex
      width: 164px
      height: 48px
      justify-content: center
      align-items: flex-end
      box-sizing: border-box
      background-color: $c-white
      border-radius: 8px
      position: relative
      margin: -20px auto 0 auto
      z-index: -1

      p
        font-weight: bold
        text-align: center
        margin-top: auto
        color: $c-black
        +fontsize(16px, 22px)

  &__points-container
    display: flex

    h3
      +fontsize(42px, 1.3)

    h4
      margin-left: 10px
      +fontsize(26px, 1.3)

  &__current
    height: auto
    margin-top: auto
    margin-bottom: 70px
    flex-direction: column
    box-sizing: border-box
    background-color: $c-darkblue
    border-radius: 8px
    padding: 20px

    +mq($until: tablet)
      margin-bottom: 0

  &__wrapper
    width: stretch

  &__stats
    box-sizing: border-box
    display: flex
    border-radius: 8px
    padding: 20px
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

    +mq($from: tablet)
      margin-right: 20px

    +mq($until: tablet)
      max-width: 480px
      height: 305px
    +mq($until: desktop)
      margin-bottom: 20px

    &--left
      flex-grow: 100
      display: flex
      flex-direction: column
      position: relative
      align-items: center

      +mq($from: mobile)
        margin-right: 20px
        align-items: unset

    &--right
      flex: 0 0 50%
      display: none
      +mq($from: mobile)
        display: flex

  &__board
    box-sizing: border-box
    width: 100%
    width: stretch
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    //height: 402px
    border-radius: 8px
    padding: 20px
    position: relative

    & > h3
      margin-bottom: 20px

    &--wrapper
      display: flex
      flex-direction: column
      overflow-x: auto
      overflow-y: hidden
      position: relative

      +mq($from: desktop)
        overflow-y: auto
        max-height: 330px
        min-height: 330px
        overflow-x: unset

      &:after
        content: ""
        display: block
        opacity: 1
        visibility: visible
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 100px
        background: linear-gradient(to bottom, rgba(34,34,72,0) 0%,rgba(39,39,75,1) 100%)
        transition: all 0.3s ease-in-out

      &--scroll
        &:after
          opacity: 0
          visibility: hidden

  &__row
    display: flex
    min-height: 72px
    max-height: 72px

    &:not(:last-of-type)
      margin-bottom: 10px

    &:last-child
      .heros-ranking__item,
      .heros-ranking__score
        +gradient-blue-medium

  &__item
    align-items: center
    display: flex
    background-color: $c-darkblue
    border-radius: 8px
    box-sizing: border-box
    padding: 10px 20px
    flex-grow: 100
    margin-right: 10px

    .icon
      +icon-color($c-white)
      +icon-size(42px)
      min-width: 42px
      min-height: 42px
      margin-right: 20px

    h3
      +fontsize(18px, 1.22)
      white-space: nowrap

    h3:first-of-type
      margin-right: 10px
      color: $c-orange

    p
      margin-left: auto
      white-space: nowrap

    & > span:last-of-type
      display: flex
      flex-wrap: wrap
      padding-right: 10px

    &--all h3:first-of-type
      color: $c-white

  &__score
    min-width: 225px
    min-height: 72px
    padding: 10px 20px
    box-sizing: border-box
    background-color: $c-darkblue
    border-radius: 8px

  &__avatar
    width: 220px
    height: 362px
    border-radius: 8px
    overflow: hidden

    +mq($until: tablet)
      width: 220px
      height: 265px

    .image, .image img
      width: 100%
      height: 100%
      object-fit: cover

  &__actions
    margin-top: 40px
    position: relative
    display: block
    text-align: center

  &__left
    display: flex
    flex-direction: column
    margin-right: 20px

    h3
      display: none

    p
      max-width: 480px
      max-height: 277px
      display: none
      +transition

      +mq($from: tablet)
        display: none
        margin-bottom: 30px

        .heros-ranking__section--open &
          display: block

  &__right
    display: flex
    height: stretch


.heros-ranking-new-state
  width: 100%
  position: relative
  box-sizing: border-box
  display: flex

  @media (max-width: 1214px)
    flex-wrap: wrap

    .heros-ranking__stats,
    .heros-ranking__board
      flex: 0 0 100%
      max-width: 100%

    .heros-ranking__stats
      height: 150px

  .heros-ranking__stats
    flex-wrap: wrap

  .heros-ranking__stats--left,
  .heros-ranking__stats--right
    flex: 0 0 100%
    max-width: 100%

  .heros-ranking__stats--right
    height: 220px
    align-self: flex-end

    @media (max-width: 1214px)
      width: 220px

      .heros-ranking__avatar
        display: none

  .heros-ranking__avatar .image
    width: 100%
    height: 220px
    object-fit: none
    align-self: flex-end

  .heros-ranking__row
    min-height: 45px

  .heros-ranking__row:last-child
    .heros-ranking__item
      background: #0F0F31

  .heros-ranking__row-active
    .heros-ranking__item
      +gradient-blue-medium

  .heros-ranking__item
    flex: 0 0 20%
    max-width: 20%

    &:first-child
      flex: 0 0 15%
      max-width: 15%

    span
      text-overflow: ellipsis
      max-width: 90%
      overflow: hidden
      display: block
      white-space: nowrap

.heros-ranking__top-bar
  display: flex
  margin-bottom: 10px

  .heros-ranking__item
    flex: 0 0 20%
    max-width: 20%
    background: $c-white
    color: $c-black

.heros-ranking__row--4
  justify-content: center

  .heros-ranking__item
    flex: 0 0 24%
    max-width: 24%

    &:first-child
      flex: 0 0 24%
      max-width: 24%

    &:last-child
        margin: 0

  .heros-ranking__top-bar
    justify-content: center

    .heros-ranking__item
      flex: 0 0 24%
      max-width: 24%

      &:last-child
        margin: 0
