.teamTrophy
  background-color: $c-darkblue

  /*
   * BEGIN promise section
   */







  .promise
    &__section
      display: flex
      background-color: $c-lightgrey
      justify-content: center
      padding: 25px 20px 40px

      +mq($from: mobile)
        +mq($until: tablet)
          top: 20px
          position: relative

      +mq($until: mobile)
        align-items: center
        flex-direction: column
        padding-top: 70px

      //"Informationen" btn
      > button.button--bg-white
        +mq($from: desktop)
          display: none

    &__counter
      max-width: 580px
      width: 100%
      width: stretch
      border-radius: 8px
      background-color: $c-white
      box-sizing: border-box
      color: $c-black
      display: flex
      flex-direction: column
      padding: 71px 20px 20px 80px
      position: relative
      +transition

      & > h2
        margin-bottom: 10px

      & > p
        +mq($from: mobile)
          width: 366px

      +mq($until: desktop)
        margin-right: 50px

      +mq($until: tablet)
        max-width: 452px
        padding: 71px 20px 20px 20px

      +mq($until: mobile)
        margin-right: 0
        max-width: none

      .counter__icon
        position: absolute
        top: 0
        left: 50%
        transform: translate(-50%, -50%)
        display: flex
        border-radius: 50%
        width: 80px
        height: 80px
        justify-content: center
        align-items: center
        background-color: $c-white
        +drop-shadow
        z-index: 5

        .lottie__uncontrolled
          width: 50px
          height: 50px

  // dark-blue container with bubble
  .promise-info
    &__container
      width: 180px

      +mq($until: mobile)
        margin-top: 70px
        margin-bottom: 20px

      +mq($from: mobile)
        margin-top: 20px

      +mq($from: desktop)
        margin-left: -30px
        margin-top: 50px
        //margin-top: 0
        //position: absolute
        //transform: translateX(345px) translateY(50px)

    &__content
      +gradient-blue-dark
      border-radius: 8px
      color: $c-white
      font-weight: bold
      padding: 10px 20px
      position: relative
      text-align: center

      &:last-of-type
        text-align: left

      .value
        font-size: 24px

        &-large
          font-size: 42px

      $bubble-size: 50px

      .bubble
        align-items: center
        border-radius: 50%
        background-color: $c-white
        color: $c-darkred
        display: flex
        +drop-shadow
        height: $bubble-size
        justify-content: center
        transform: rotate(-10deg)
        position: absolute
        width: $bubble-size

        &-top-left
          left: -$bubble-size/2
          top: -$bubble-size/2

        &-m-top-center
          +mq($until: mobile)
            left: 50%
            transform: translateX(-50%) rotate(-10deg)
            top: -45px

    &__content + .promise-info__content
      margin-top: 10px
  /*
   * END promise section
   */

  /*
   * BEGIN recommendations section
   */













  .recommendations
    margin: 0
    display: flex
    flex-direction: column
    align-items: center

    &__section
      display: flex
      justify-content: center
      +transition

      +mq($until: phone)
        padding: 22px 10px

      +mq($until: mobile)
        padding: 44px 10px

    h2
      color: $c-white
      text-align: center

  &-performance__box
    border-radius: 8px
    display: flex
    flex-direction: column
    width: max-content
    color: $c-black
    padding: 20px 10px
    box-sizing: border-box
    align-items: center
    background: $c-white
    margin: 10px
    height: 270px
    +transition

    +mq($from: mobile)
      align-items: flex-start
      padding: 40px
    +mq($from: tablet)
      max-width: 480px
      height: 320px

    &--timer
      height: auto
      max-height: 320px

      +mq($from: tablet)
        max-width: 680px

      & > h3
        margin-bottom: 20px
        text-align: center
        width: 100%

      & > span
        display: flex
        justify-content: center
        width: 100%

      .progress-circle
        font-size: 86px

        +mq($from: mobile)
          font-size: 180px

        span
          color: $c-black

        &.c100
          &:after
            background: $c-white

        & > span:first-of-type
          +fontsize(39px, 39px)

          +mq($from: mobile)
            +fontsize(70px, 70px)
