.neukunden-performance

  &__section
    padding: 64px 10px 20px 10px
    display: flex
    flex-direction: column
    background-color: $c-darkblue
    position: relative

    +mq($from: desktop)
      padding: 40px 130px


    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      transform: translate(-50%, -50%)

  &__content
    display: flex
    flex-direction: column

    .card-slider__label
      color: $c-white

    +mq($from: tablet)
      align-items: center

  &__wrapper
    width: 100%
    display: flex
    flex-direction: column
    margin-bottom: 40px
    +transition

    +mq($from: tablet)
      margin-bottom: 80px
      justify-content: flex-start
      align-items: center
      +transition

    +mq($from: desktop)
      flex-direction: row
      flex-wrap: wrap
      align-items: flex-start
      width: 100%

  &__item
    display: flex
    width: 100%
    border-radius: 8px
    align-items: center
    box-sizing: border-box

    &:not(:last-of-type)
      margin-bottom: 10px

    &--current
      color: $c-orange

      .icon
        +icon-color($c-orange)

    & > span
      height: 48px
      box-sizing: border-box
      background: $c-darkblue
      border-radius: 8px
      align-items: center
      font-weight: bold
      padding: 15px 10px
      display: flex
      justify-content: flex-start
      +transition

      +mq($from: mobile)
        height: 72px
        padding: 15px 20px
        +transition

      .icon
        display: none
        margin-right: 20px
        +icon-size(36px)
        +icon-color($c-white)

        +mq($from: mobile)
          display: block

      &:last-of-type
        min-width: 120px
        width: 100%
        +fontsize(18px, 9px)
        +transition

        +mq($from: mobile)
          +fontsize(42px, 42px)
          +transition

      & > sup
        margin-left: 5px
        margin-bottom: 10px
        font-size: 14px
        +transition
        +mq($from: mobile)
          margin-bottom: 20px
          +transition

      &:first-of-type
        min-width: 120px
        width: 100%
        margin-right: 10px
        +fontsize(18px, 18px)

  &__box
    border-radius: 8px
    display: flex
    flex-direction: column
    width: 100%
    color: $c-white
    padding: 20px 10px
    box-sizing: border-box
    align-items: center
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

    +mq($from: mobile)
      padding: 20px

    +mq($from: tablet)
      flex: 50 50

      +mq($until: desktop)
        max-width: 580px

    &--list
      background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
      padding: 20px 10px

      +mq($from: desktop)
        width: 100%

      .neukunden-performance__wrapper
        margin-bottom: 20px

    &--timer
      height: 100%

      +mq($from: tablet)
        max-width: 680px
        min-height: 220px
      // IE fix
      +mq($from: desktop)
        margin-left: 20px

      & > span
        display: flex
        justify-content: center
        width: 100%

      .progress-circle
        font-size: 86px
        margin-bottom: 0

        +mq($from: mobile)
          font-size: 180px

        & > span:first-of-type
          +fontsize(39px, 39px)

          +mq($from: mobile)
            +fontsize(70px, 70px)

    &-header
      display: flex
      width: 100%
      margin-bottom: 25px

      div
        display: flex
        justify-content: flex-start
        align-items: center
        flex: 50 50
        padding: 0 0 0 10px

        +mq($from: mobile)
          h4
            +fontsize(24px, 1.16)
            +transition


    +mq($until: desktop)
      &:not(:last-of-type)
        margin-bottom: 20px

    & > h3
      margin-bottom: 20px
      text-align: center

      +mq($from: mobile)
        text-align: left

    &--barometer
      position: relative

      +mq($from: desktop)
        min-width: 580px
        max-width: 580px
        margin-right: 20px

      .counter-spawner
        margin-right: 20px
        display: flex
        align-items: center
        justify-content: center

        .counter
          width: 55px
          height: 80px
          +transition

          +mq($from: mobile)
            width: 78px
            height: 114px

        .counter__value
          +fontsize(70px, 70px)

          +mq($from: mobile)
            +fontsize(101px, 101px)

    &-container
      display: flex
      flex-direction: row
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 20px

      &--list
        width: 100%
        flex-direction: column

    &-wrapper
      display: flex
      margin-bottom: 20px
      +transition

      +mq($from: mobile)
        +mq($until: tablet)
          flex: 0 0 50%
          +transition

      .counter
        width: 33px
        height: 48px

        &__value
          +fontsize(33px, 33px)

    &-type
      justify-content: center
      display: flex
      +transition

    & > a
      margin-top: 40px

  &__actions
    margin-top: 40px
    display: flex
    width: 100%
    justify-content: center

  &__congrats
    border-radius: 50%
    width: 56px
    min-width: 56px
    height: 56px
    background-color: $c-white
    display: flex
    justify-content: center
    align-items: center
    margin-top: 14px

    svg
      width: 34px !important
      height: 34px !important

