.salestriathlon-header__section
  background-image: url("/images/app/heros-place/img--bg-herosplace.jpg")
  background-position: bottom
  height: 412px
  overflow: hidden
  +transition

  &.app-header__section--open
    height: auto
    // height: 612px
    +transition

    +mq($from: tablet)
      .app-header__box
        width: auto
        height: auto
        height: auto
        bottom: -30px
        right: 124px

  +mq($from: mobile)
    background-position: bottom center
    height: 540px

    &.app-header__section--open
      height: 810px

  +mq($from: tablet)
    height: 310px
    background-position: 70% 70%
    &.app-header__section--open
      height: 840px


  .app-header__heading h1
    text-align: center

  .app-header__box
    display: block
    width: 311px
    height: unset
    left: unset
    right: 0
    bottom: 0
    +transition

    +mq($from: tablet)
      width: 600px
      height: auto
      bottom: 0
      +transition
