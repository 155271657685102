.apps-header-list
  box-sizing: border-box
  width: 100%
  position: relative
  padding-bottom: 60px

  &__heading
    text-align: center
    padding: 30px 0

  .salespush-performance__section,
  .neukunden-performance__section,
  .schaffe-ich-room__section,
  .casino-room__section
    padding-bottom: 80px

.light-mode
  .apps-header-list

.dark-mode
  .apps-header-list
    background-color: $c-darkblue
    
    &__heading
      background: transparent linear-gradient(180deg, #27274B 0%, #222248 100%) 0% 0% no-repeat padding-box
      color: $c-white