.salestriathlon-performance

  &__section
    padding: 64px 10px 20px 10px
    display: flex
    flex-direction: column
    background-color: $c-darkblue
    position: relative

    +mq($from: desktop)
      padding: 40px 130px


    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      transform: translate(-50%, -50%)

  &__goals
    width: 100%
    padding-bottom: 40px

    &-container
      display: flex
      justify-content: space-between
      width: 100%

    &-single
      display: flex
      flex-direction: column
      width: 280px
      align-items: center
      transform: scale(0.9)

      &--current, &--achieve
        transform: scale(1)

    &-head
      background-color: $c-blue
      border-top-left-radius: 8px
      border-top-right-radius: 8px
      text-align: center
      padding: 12px 0 10px
      width: 240px

      & > h3
        color: $c-white

    &-upper
      background-color: $c-white
      color: $c-black
      text-align: center
      width: 100%
      border-top-left-radius: 8px
      border-top-right-radius: 8px
      padding: 20px 0

      &--achieve
        background-color: $c-orange
        color: $c-white

      & > p
        +fontsize(20px, 28px)

      & > h2
        font-weight: bold
        +fontsize(62px, 69px)

    &-center
      background-color: $c-blue
      color: $c-white
      text-align: center
      width: 100%
      padding: 16px 0

    &-lower
      background-color: $c-orange
      color: $c-white
      text-align: center
      font-weight: bold
      width: 100%
      padding: 28px 0 48px
      border-bottom-left-radius: 8px
      border-bottom-right-radius: 8px
      position: relative

      &--achieve
        background-color: $c-blue
        position: relative
        height: 190px

        .tachometer__container
          position: absolute
          left: 0
          bottom: 0


      & > h2
        font-weight: bold
        +fontsize(62px, 69px)

    &-percent
      position: absolute
      bottom: 0
      left: 50%
      transform: translate(-50%, 50%)
      width: 180px
      height: 56px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 8px
      +gradient-blue-medium


  &__content
    display: flex
    flex-direction: column

    .card-slider__label
      color: $c-white

    +mq($from: tablet)
      align-items: center

  &__box
    border-radius: 8px
    display: flex
    flex-direction: column
    width: 100%
    color: $c-white
    padding: 20px 10px
    box-sizing: border-box
    align-items: center
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

    +mq($from: mobile)
      padding: 20px

    +mq($from: tablet)
      flex: 50 50

      +mq($until: desktop)
        max-width: 580px

    &--timer
      height: 100%

      +mq($from: tablet)
        max-width: 680px
        min-height: 220px
      // IE fix
      +mq($from: desktop)
        margin-left: 20px

      & > span
        display: flex
        justify-content: center
        width: 100%

      .progress-circle
        font-size: 86px
        margin-bottom: 0

        +mq($from: mobile)
          font-size: 180px

        & > span:first-of-type
          +fontsize(39px, 39px)

          +mq($from: mobile)
            +fontsize(70px, 70px)

    +mq($until: desktop)
      &:not(:last-of-type)
        margin-bottom: 20px

    & > h3
      margin-bottom: 20px
      text-align: center

      +mq($from: mobile)
        text-align: left

  &__actions
    margin-top: 40px
    display: flex
    width: 100%
    justify-content: center


.banner-section .banner-section-inner .app-header__section .app-header__box.app-header__box--triathlon
  top: 150px

.banner-section .banner-section-inner--open .app-header__section .app-header__box.app-header__box--triathlon
  max-width: inherit
  right: 0
  width: 100%
  max-height: 630px
  top: 220px