.salestriathlon-goals
  &__section
    display: flex
    width: 100%
    position: relative

    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      transform: translate(-50%, -50%)

  &__content
    width: 100%

  &__inner
    display: flex
    flex-wrap: wrap
    padding: 40px 170px

    +mq($until: widescreen)
      padding: 40px 100px

    +mq($until: desktop)
      padding: 40px 20px

    &--blue
      background-color: $c-darkblue

    &--grey
      background-color: $c-lightgrey

      .salestriathlon-goals__heading
        color: $c-black

    &--accomplished
      padding-bottom: 100px

      .salestriathlon-goals__single--finished
        transform: scale(1)

        .salestriathlon-goals__upper
          background-color: $c-orange
          color: $c-white

        .salestriathlon-goals__center
          .thumbsup
            display: block

        &.salestriathlon-goals__single--not-accomplished
          .salestriathlon-goals__upper
            background-color: $c-white
            color: $c-black

          .salestriathlon-goals__center
            .thumbsup
              display: none

      .salestriathlon-goals__single--current
        .salestriathlon-goals__center
          opacity: 1

        &.salestriathlon-goals__single--accomplished
          transform: scale(1)

          .salestriathlon-goals__upper
            background-color: $c-orange
            color: $c-white

          .salestriathlon-goals__center
            .thumbsup
              display: block


      .salestriathlon-goals__upper
        padding-top: 30px
        
      .salestriathlon-goals__center
        .thumbsup
          background-color: $c-orange
          width: 70px
          height: 58px
          display: block
          border-radius: 50%
          padding-top: 12px
          position: absolute
          top: 15px
          right: 10px
          z-index: 3
          display: none

          .icon
            width: 40px

  &__heading
    text-align: center
    width: 100%
    color: $c-white
    margin-bottom: 40px
  
  &__container
    width: 100%
    display: flex
    justify-content: space-between

    +mq($until: tablet)
      width: 1000px
      justify-content: inherit
      overflow: scroll

  &__single
    width: 26%
    text-align: center

    +mq($until: tablet)
      min-width: 260px
      margin-right: 20px

    &--current
      .salestriathlon-goals__head, 
      .salestriathlon-goals__upper
        opacity: 1

      &.salestriathlon-goals__single--accomplished
        transform: scale(1.1)

        .salestriathlon-goals__lower
          background-color: $c-orange
          color: $c-white
          opacity: 1
        
        .salestriathlon-goals__center
          opacity: 1

        .salestriathlon-goals__upper
          .thumbsup
            display: block

    &--finished
      transform: scale(1.1)

      +mq($until: desktop)
        transform: scale(1)

      .salestriathlon-goals__head, 
      .salestriathlon-goals__upper,
      .salestriathlon-goals__center, 
      .salestriathlon-goals__lower
        opacity: 1

      .salestriathlon-goals__lower
        background-color: $c-orange

      .salestriathlon-goals__upper
        .thumbsup
          display: block

      &.salestriathlon-goals__single--not-accomplished
        .salestriathlon-goals__lower
          background-color: $c-white
          color: $c-black

          svg use
            fill: $c-black

        .salestriathlon-goals__upper
          .thumbsup
            display: none

  &__head
    color: $c-white
    text-transform: uppercase
    background: linear-gradient(0deg, rgba(39,39,75,1) 0%, rgba(34,34,72,1) 100%)
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    max-width: 75%
    padding: 10px 0 3px
    margin: 0 auto
    opacity: 0.5

  &__upper
    background-color: $c-white
    padding: 15px 0
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    position: relative
    opacity: 0.5

    p
      margin: 0
      font-size: 20px
    
    h2
      font-size: 56px
      font-weight: 700
      margin: -13px 0 -20px
    
    .thumbsup
      background-color: $c-orange
      width: 70px
      height: 58px
      display: block
      border-radius: 50%
      padding-top: 12px
      position: absolute
      top: -15px
      right: -15px
      display: none

      .icon
        width: 40px
  
  &__center
    color: $c-white
    padding: 15px 0 10px
    background: linear-gradient(0deg, rgba(39,39,75,1) 0%, rgba(34,34,72,1) 100%)
    opacity: 0.5
    position: relative
  
  &__lower
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    background-color: $c-grey
    padding: 30px 0 0
    color: $c-white
    opacity: 0.5
    position: relative

    h2
      font-size: 56px
      font-weight: 700

      span
        font-size: 36px
        position: relative
        top: -15px
  
    .icon
      width: 190px
      top: -90px

      svg use
        fill: $c-white
  
  &__percent
    background: linear-gradient(0deg, rgba(70,70,118,1) 0%, rgba(54,54,105,1) 100%)
    max-width: 150px
    margin: -15px auto 0
    padding: 10px 0
    position: relative
    z-index: 3
    border-radius: 8px
    top: 35px
    text-align: center
    color: $c-white

    h3
      font-size: 32px
      margin: 0 0 -5px
      line-height: inherit

  &__info
    max-width: 760px
    margin: 0 auto

  &__sum
    display: flex
    align-items: stretch
    justify-content: center

    +mq($until: mobile)
      flex-wrap: wrap

    h2
      font-size: 56px
      font-weight: 700

      span
        font-size: 36px
        position: relative
        top: -15px

  &__left
    padding-right: 10px
    width: 50%

    +mq($until: mobile)
      width: 100%
      margin-bottom: 20px

  &__right
    padding-left: 10px
    width: 50%

    +mq($until: mobile)
      width: 100%

    p
      margin-bottom: 10px

      &:first-child
        margin-bottom: 20px
  
  &__box
    box-shadow: 0 0 5px rgba(0,0,0,0.2)
    height: 100%
    border-radius: 8px

  &__top
    height: 50%
    display: flex
    align-items: center
    justify-content: center

  &__bottom
    height: 50%
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    background-color: $c-orange
    color: $c-white
    display: flex
    align-items: center
    justify-content: center

  &__performance
    width: 100%
    display: flex
    align-items: stretch

    +mq($until: tablet)
      flex-wrap: wrap

  &__performance-left
    width: 30%
    padding-right: 10px

    +mq($until: tablet)
      width: 100%
      margin-bottom: 60px
      padding-right: 0

  &__performance-right
    width: 70%
    padding-left: 10px

    +mq($until: tablet)
      width: 100%
      padding-left: 0
      min-height: 230px

  &__performance-top
    background-color: $c-white
    color: $c-black
    text-align: center
    border-radius: 8px
    margin-bottom: 20px
    padding: 25px 0 15px

    &.salestriathlon-goals__performance-top--accomplished
      background-color: $c-orange
      color: $c-white

    h2
      font-size: 56px
      font-weight: 700
      margin: -13px 0 -20px

  &__performance-bottom
    background: linear-gradient(0deg, rgba(39,39,75,1) 0%, rgba(34,34,72,1) 100%)
    border-radius: 8px
    padding-top: 25px

  &__bigbox
    background: linear-gradient(0deg, rgba(39,39,75,1) 0%, rgba(34,34,72,1) 100%)
    border-radius: 8px
    text-align: center
    color: $c-white
    height: 100%

    h3
      position: relative
      top: 20px
      margin-bottom: 50px

  &__circles
    display: flex
    justify-content: center