.order-item
  background-color: $c-white
  display: flex
  flex-direction: row
  border-radius: 8px
  box-sizing: border-box
  padding: 10px
  flex-wrap: wrap
  min-height: 59px
  //max-width: 880px

  .orders--detail &
    max-width: unset

  +mq($from: mobile)
    flex-wrap: unset
  +mq($from: tablet)
    min-height: 108px
    padding: 20px

  &__shipping
    +fontsize(16px, 1.5)

  &__wrapper
    display: flex
    flex-grow: 1
    padding-left: 10px

  &__image
    display: block
    overflow: hidden
    max-width: 32px
    width: stretch
    max-height: 70px
    +transition

    .orders--detail &
      max-width: 40px

    +mq($from: tablet)
      max-width: 70px
      +transition

    .icon
      +icon-size(32px)
      +transition

      .orders--detail &
        +icon-size(40px)


      +mq($from: tablet)
        +icon-size(68px)
        +transition


  &__row
    display: inline-flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

  &__content
    display: flex
    flex-direction: column
    flex-grow: 1
    align-items: flex-start
    padding-right: 10px
    padding-left: 10px
    +transition

    +mq($from: tablet)
      justify-content: center
      padding-left: 20px
      +transition

  &__title
    font-weight: bold
    +fontsize(18px, 1.22)
    word-break: break-all

    +mq($from: mobile)
      padding-bottom: 5px
      +fontsize(24px, 1.16)

  &__detail
    +fontsize(16px, 1.5)

  &__details
    min-width: 170px
    padding-left: 52px
    padding-top: 5px
    +mq($from: mobile)
      padding-left: 0

  &__price
    +fontsize(24px, 1.16)
    font-weight: bold
    color: $c-orange
    margin-bottom: 10px

.order-item + .order-item
  margin-top: 20px

