.profile-address

  &__section
    padding: 40px 130px
    background-color: $c-lightgrey

    +mq($until: tablet)
      padding: 20px 44px
      +transition

    +mq($until: phone)
      padding: 20px 10px
      +transition


    h2
      text-align: center
      padding-bottom: 40px

      +mq($until: phone)
        padding-bottom: 20px

  &__container
    display: flex
    margin: -10px
    justify-content: center
    flex-wrap: wrap

  &__item
    border-radius: 8px
    background-color: $c-white
    box-sizing: border-box
    max-width: 280px
    width: stretch
    margin: 10px
    display: flex
    flex-direction: column
    justify-content: space-between

  &__add-new
    background-color: $c-lightgrey
    border: 1px dotted $c-brightgrey
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    height: 280px
    width: 280px

    &:hover, &:focus
      .profile-address__icon
        background-color: $c-orange
        +scale(1.05)
        +transition

        .icon
          +icon-color($c-white)

    .profile-address__content
      border-bottom: 0

    b
      text-align: center

    &-modal
      position: fixed
      z-index: 200
      top: 40px
      height: calc(100% - 88px)
      width: calc(100% - 40px)
      left: 50%
      transform: translateX(-50%)

      +mq($from: phone)
        width: stretch
        max-width: calc(100% - 88px)
      +mq($from: mobile)
        max-height: max-content
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

      +mq($from: tablet)
        max-width: 820px
        height: auto
        height: max-content

      .profile__personal-data
        background: $c-white
        color: $c-black
        height: 100%
        overflow: auto

        +mq($from: tablet)
          height: max-content

        label
          color: $c-grey

        .profile-header__actions
          padding-top: 60px
          padding-bottom: 20px

      &-close
        display: flex
        justify-content: flex-end
        cursor: pointer

        .icon
          +icon-color($c-black)
          +icon-size(18px)

  &__icon
    width: 64px
    height: 64px
    background-color: $c-white
    display: flex !important
    justify-content: center
    align-items: center
    border-radius: 50%
    margin: 0 auto 20px auto
    +transition

    .icon
      +icon-size(32px)
      +icon-color($c-grey)

  &__content
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    padding: 20px

    & > b:first-of-type
      text-transform: capitalize

    & > b, & > span
      display: block

  &__actions
    display: flex
    border-top: 1px solid $c-brightgrey
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    padding: 20px
    align-items: center

    .button
      height: 18px
      font-size: 12px
      line-height: 1.33
      padding-left: 4px
      padding-right: 4px
      border-radius: 0
      margin: 0
      font-weight: normal
      transform: none
      +transition

      &:first-of-type
        padding-left: 0

        &:hover, &:focus
          padding-left: 0

      &:hover, &:focus
        +scale(1)
        color: $c-black
        height: 18px
        font-size: 12px
        line-height: 1.33
        padding-left: 4px
        padding-right: 4px
        transform: none
        margin: 0
        text-decoration: underline
        width: auto
        +transition