.gloria-rewards-app.homepage.light-mode
  font-family: 'Titillium Web', sans-serif

  .button
    font-family: 'Titillium Web', sans-serif

  .navbar__user-bar
    background-color: $c-darkgrey

  .navbar__micro-user,
  .navbar__micro-menu,
  .navbar__micro-user .navbar__user-bar,
  .textfield__input, 
  .textfield .flatpickr-input, 
  .textfield .flatpickr-input.flatpickr-mobile,
  .textarea__input,
  .profile__personal-data,
  .profile__newsletter,
  .profile-address__item
    border-radius: 0

  .footer
    background-color: $c-black

  .product-link
    overflow: hidden
    display: block

  .product__label-highlight,
  .product__label-new,
  .product__label-luxus
    text-transform: uppercase
    background-color: $c-luxusgold
    color: $c-darkgrey
    transform: rotate(-45deg)
    display: inline-block
    padding: 5px 60px
    position: absolute
    top: 30px
    left: -40px
    box-shadow: 0 0 10px rgba($c-black, 0.2)

  .product__label-highlight
    background-color: $c-silvergrey

  .product__label-new
    background-color: $c-white

  .reward-categories__highlights-all
    background-color: $c-black

  .header.header--no-image > h1, 
  .header.header--no-image > h2
    color: $c-white

  .header h1
    font-weight: normal

  .account-header__section,
  .contracts,
  .profile-header__section
    background-color: $c-lightgrey
    color: $c-black

  .account__transaction,
  .profile__personal-data, 
  .profile__newsletter
    background: $c-darkgrey
    color: $c-white

  .account__filter > h2
    color: $c-black

  .profile-header__actions .button
    color: $c-black

    &:hover, &:focus
      color: $c-white
  
  .profile-address__add-new:hover .profile-address__icon, 
  .profile-address__add-new:focus .profile-address__icon
    background-color: $c-black

  .selectfield__selected, 
  .selectfield__items div
    border-radius: 0

  .profile-address__add-new-modal
    .profile__personal-data
      background-color: $c-white

      h2
        color: $c-black
      
      .button
        color: $c-white

  // Navbar
  &.container--scroll
    margin-top: 0

  .navbar__container
    position: fixed
    background-color: rgba($c-black, 0.8)
  
  .navbar__user
    background-color: transparent

    .button
      font-size: 18px

  // Content
  .gloria-rewards
    &__page
      width: 100%

      // Global
      .button
        &--secondary
          border-color: $c-white
          
        &:hover, &:focus
          background-color: $c-white

        &.button--icon--right
          .icon
            position: absolute
            top: 26px
            right: 15px
            left: auto
            max-width: 20px
          
          &.button--has-icon
            padding: 0 45px 0 20px
            display: inline-block
            text-decoration: none

    // Header Slider
    .app-header__section
      background-color: $c-black
      background-image: url("/images/app/gloria-rewards/img--heroslider-anim-bg.jpg")
      background-position: center

    .app-header__content
      padding-top: 180px
      height: 540px

    .app-header__slider,
    .app-header__bg,
    .app-header__slider .tns-inner
      height: 700px

    .app-header__slider h1:after, 
    .app-header__slider h2:after
      border-radius: 0 !important
    
    .app-header__slider h1,
    .app-header__slider h2
      line-height: 1
      text-transform: uppercase
      font-weight: normal
      color: $c-black
      // left: 100px

      +mq($until: tablet)
        left: 0
    
    .app-header__slider h1
      padding-bottom: 5px 
      font-size: 76px

      +mq($until: mobile)
        font-size: 55px

      +mq($until: phone)
        font-size: 40px
    
    .app-header__slider h2
      padding-bottom: 12px
      font-size: 32px

    .app-header__item--dark h1, 
    .app-header__item--dark h2
      color: $c-white

    .app-header__arrow
      top: auto
      margin-top: 0
      bottom: 10px
      background-color: transparent
      z-index: 10

    .app-header__heading div,
    .app-header__heading div > span
      display: block

    .app-header__button-bottom
      position: absolute
      bottom: 60px
      left: 0
      z-index: 30
      width: 100%
      text-align: center

      a
        display: inline-block
        border-radius: 32px
        color: $c-black
        font-size: 16px
        cursor: pointer
        padding: 8px 35px
        +transition
        background-color: $c-white
        border: 1px solid $c-white
        text-decoration: none

        &:hover
          color: $c-white
          background-color: $c-black
          border-color: $c-black
          transform: perspective(1px) scale(1.05)
      
    .app-header__icon-logo
      width: 60px
      height: 60px
      background-color: $c-black
      border: 1px solid $c-white
      border-radius: 15px
      padding: 6px 10px 10px 10px
      position: absolute
      left: auto
      top: auto

      +mq($until: tablet)
        position: relative
        margin-bottom: 30px

      .icon
        width: 60px
        height: 60px

        svg use 
          fill: $c-white

    // Intro
    &__intro
      padding: 60px 130px
      color: $c-white
      background-image: url("/images/app/gloria-rewards/img--heroslider-anim-bg.jpg")
      background-position: center
      justify-content: center

      +mq($until: desktop)
        padding: 60px 20px

      &--active
        padding: 20px 130px

        +mq($until: desktop)
          padding: 20px 20px
      
      &--inner
        max-height: 0
        overflow: hidden
        max-width: 620px
        margin: 0 auto

        +mq($until: tablet)
          width: 100%
          max-width: 100%
          box-sizing: border-box

        h3
          margin-bottom: 20px
          font-size: 32px

        h4
          font-size: 24px
          margin-bottom: 15px

        h5
          font-size: 18px
          text-transform: uppercase
          margin-bottom: 10px

        p
          margin-bottom: 20px

        .button
          float: right
          margin-top: 30px
          position: relative
          z-index: 1

          &:hover, &:focus
            color: $c-black

          +mq($until: mobile)
            float: inherit
            margin-bottom: 30px

      &--inner-active
        max-height: 10000px
        overflow: auto
        padding: 40px

        +mq($until: mobile)
          padding: 0

      &--buttons
        position: relative
        text-align: center
        z-index: 0

        &-active
          top: -88px
          margin-bottom: -44px

          +mq($until: mobile)
            top: 0
            margin-bottom: 10px

        button
          &:hover, &:focus
            color: $c-black

          .icon
            position: absolute
            right: 30px
            left: auto
            max-width: 20px
          
          &.button--has-icon
            padding: 0 60px 0 60px
            display: inline-block
            text-decoration: none
        
          &.button--icon-up
            .icon
              top: 10px
              transform: rotate(-90deg)
          
          &.button--icon-down
            .icon
              top: 13px
              transform: rotate(90deg)

    // Featured
    &__featured
      width: 100%
      display: flex
      flex-wrap: wrap

      a
        display: block
        height: 450px

      .product__price
        display: none
      
      .product
        width: 100%
        max-width: 100%
        min-width: auto
        position: relative

      .product__upper, 
      .product__content
        border-radius: 0

      .product:hover > .product__price, 
      .product:focus > .product__price, 
      .product:hover > .product__upper, 
      .product:focus > .product__upper
        box-shadow: inherit

      .product:hover 
        .read-more
          color: $c-white
          background-color: $c-black
          border-color: $c-black
          transform: perspective(1px) scale(1.05)

      .product__image
        max-width: 100%
        height: 100%
        border-radius: 0       

      .product__image .image
        padding: 0
        height: 450px

      .product__content
        background-color: transparent
        position: absolute
        bottom: 40px
        left: 40px
        display: block
        justify-content: inherit
        align-items: inherit
        padding: 0

        +mq($until: phone)
          left: 0
          bottom: 0

        .read-more
          display: inline-block
          border-radius: 32px
          color: $c-black
          font-size: 16px
          cursor: pointer
          padding: 8px 35px
          +transition
          background-color: $c-white
          border: 1px solid $c-white

          +mq($until: phone)
            display: none
      
      .product__title
        background-color: rgba($c-black, 0.8)
        color: $c-white
        height: auto
        padding: 20px
        width: 100%
        max-width: 300px
        margin-bottom: 20px
        font-weight: normal
        text-align: left

        +mq($until: phone)
          max-width: 100%
          box-sizing: border-box
          margin-bottom: 0

      &--left
        width: 50%

        +mq($until: tablet)
          width: 100%

      &--right
        width: 50%

        +mq($until: tablet)
          width: 100%

    // Highlights
    &__highlights
      background-color: $c-lightgrey
      padding: 85px 130px
      width: 100%
      display: flex
      flex-wrap: wrap
      box-sizing: border-box

      +mq($until: desktop)
        padding: 60px 10px

      h2
        width: 100%
        text-align: center
        margin-bottom: 40px

      &--left
        width: 50%
        padding: 0 10px
        box-sizing: border-box

        +mq($until: tablet)
          width: 100%
          margin-bottom: 20px

      &--right
        width: 50%
        padding: 0 10px
        box-sizing: border-box

        +mq($until: tablet)
          width: 100%

      &--button
        text-align: center
        width: 100%
        margin-top: 20px

      .button--secondary
        border-color: $c-black

        &:hover, &:focus
          border-color: $c-white
          color: $c-black

      .product
        min-width: 100%
        max-width: 100%
        box-sizing: border-box

      .product__upper
        border-radius: 0

      .product__image
        max-width: 100%
        height: 350px
        border-radius: 0

      .product__actions
        right: 10px
      
      .product__price
        width: 100%
        border-radius: 0
        background-color: $c-darkgrey

      .product__content
        background-color: $c-greybg
        border-radius: 0

      .product__title
        font-weight: normal
        width: 350px
        font-size: 18px

      .button-wishlist
        background-color: rgba($c-brightgrey, 0.8)

        .icon svg use
          fill: $c-black

      .button-wishlist--active
        background-color: $c-white
    
    // Top Product
    &__topproduct
      width: 100%
      box-sizing: border-box

      a
        display: block
        height: 600px

      .product
        min-width: 100%
        max-width: 100%
        box-sizing: border-box

      .product:hover 
        .read-more
          color: $c-white
          background-color: $c-black
          border-color: $c-black
          transform: perspective(1px) scale(1.05)

      .product__upper
        border-radius: 0

      .product__image
        max-width: 100%
        height: 600px
        border-radius: 0
        padding: 0

        .image
          background-attachment: fixed
          background-repeat: no-repeat

          +mq($until: mobile)
            background-attachment: scroll

      .product__content
        background-color: transparent
        position: absolute
        bottom: 40px
        left: 40px
        display: block
        justify-content: inherit
        align-items: inherit
        padding: 0

        +mq($until: phone)
          left: 0
          bottom: 0

        .read-more
          display: inline-block
          border-radius: 32px
          color: $c-black
          font-size: 16px
          cursor: pointer
          padding: 8px 35px
          +transition
          background-color: $c-white
          border: 1px solid $c-white

          +mq($until: phone)
            display: none

      .product__title
        background-color: rgba($c-black, 0.8)
        color: $c-white
        height: auto
        padding: 20px
        width: 100%
        margin-bottom: 20px
        font-weight: normal
        box-sizing: border-box

        +mq($until: phone)
          max-width: 100%
          box-sizing: border-box
          margin-bottom: 0

      .product__price
        display: none

    // Top Product
    &__newproducts
      background-color: $c-lightgrey
      padding: 85px 135px
      width: 100%
      // display: flex
      // flex-wrap: wrap
      box-sizing: border-box

      +mq($until: desktop)
        width: auto
        padding: 60px 0
        display: block

      h2
        margin-bottom: 20px

      &--button
        text-align: center
        width: 100%
        margin-top: 20px

      .button--secondary
        border-color: $c-black

        &:hover, &:focus
          border-color: $c-white
          color: $c-black

      .product
        min-width: 100%
        max-width: 100%
        box-sizing: border-box

      .product__upper
        border-radius: 0

      .product__image
        max-width: 100%
        border-radius: 0

      .product__actions
        right: 10px
      
      .product__price
        width: 100%
        border-radius: 0
        background-color: $c-darkgrey

      .product__content
        background-color: $c-greybg
        border-radius: 0

        +mq($until: tablet)
          width: auto

      .product__title
        font-weight: normal
        width: 350px
        font-size: 18px

      .button-wishlist
        background-color: rgba($c-brightgrey, 0.8)

        .icon svg use
          fill: $c-black

      .button-wishlist--active
        background-color: $c-white