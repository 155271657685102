// Overwrite Styles
.cart .cart-product__title, .checkout .cart-product__title
  max-width: 20vw

  +mq($until: desktop)
    max-width: 70vw

  +mq($until: tablet)
    max-width: 50vw

.cart-product__action-delete
  +mq($until: mobile)
    margin-top: 0 !important
    margin-bottom: 5px