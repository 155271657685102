.button-wishlist
  display: flex
  justify-content: center
  align-items: center
  width: 46px
  border-radius: 50%
  height: 46px
  cursor: pointer
  margin-top: 0
  +transition

  &:hover
    transform: perspective(1px) scale(1.1)
    +transition
    +drop-shadow

  .icon
    +icon-size(20px)
    +icon-color($c-white)

  .icon--heart-active
    +icon-color($c-white)

  &--like
    background-color: $c-brightgrey
    +transition

  &--active
    background-color: $c-orange
    +transition

  &--club.button-wishlist--active
    background-color: $c-brightgold
