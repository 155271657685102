@keyframes fadeIn
  0%
    visibility: visible
    opacity: 0

  100%
    visibility: visible
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1

  99%
    opacity: 0

  100%
    opacity: 0
    visibility: hidden

.main-menu
  //+mq($from: phone)
  //  display: block !important

  &__product-search
    display: none

    &-header
      display: none
      margin-bottom: 40px

      & > h2
        margin-bottom: 20px

      & > span
        display: flex

        & > .main-menu__search-icon .icon
          +icon-size(20px)

        & > .textfield
          flex: 1
          outline: none

          input
            background-color: $c-white
            border-bottom: 1px solid $c-grey
            border-radius: 0

            &:active, &:focus
              border-left: none !important
              border-right: none !important
              border-top: none !important
              border-bottom: 1px solid $c-grey !important

            &::placeholder
              color: $c-grey

            &::-ms-clear
              display: none

    &--active
      display: block

      +mq($from: phone)
        width: calc(100% - 80px)
        .main-menu__product-search-header
          display: flex
          flex-direction: column

      & ~ .main-menu__profile,
      & ~ .main-menu,
      & ~ .main-menu__link-list
        display: none

  //+mq($from: phone)
  //  display: none

  &__search-result
    display: flex
    padding: 10px 0
    margin: 0 20px
    border-bottom: 1px solid $c-brightgrey
    box-sizing: border-box
    cursor: pointer

    .image
      min-width: 97px
      max-width: 97px
      height: 97px
      object-fit: contain
      margin-right: 10px

      img
        width: 100%
        height: 100%
        object-fit: contain

        .oldie &
          height: auto
          max-height: 100%
          top: 50%
          position: relative
          transform: translateY(-50%)

    &-content

      & > p:first-of-type, & > h3
        margin-bottom: 6px

      & > p:last-of-type
        max-height: 100px
        overflow: hidden
        text-overflow: ellipsis
        position: relative

        &:after
          content: "..."
          position: absolute
          right: 0
          bottom: 2px

  &__actions--mobile
    display: none

    +mq($until: phone)
      display: flex
      height: 48px
      width: 100%
      margin-bottom: 30px

      .textfield
        flex: 1
        padding-left: 10px
        outline: none

        input
          background-color: $c-white
          border-bottom: 1px solid $c-grey
          border-radius: 0

          &:active, &:focus
            border-left: none !important
            border-right: none !important
            border-top: none !important
            border-bottom: 1px solid $c-grey !important

          &::placeholder
            color: $c-grey

      .main-menu__close
        position: relative
        right: unset
        left: unset
        top: unset
        width: 53px
        height: 48px
        background-color: $c-darkgrey
        display: flex
        justify-content: center
        align-items: center

        .icon
          +icon-color($c-white)
          +icon-size(20px)
          transform: rotate(180deg)

  &__search-icon
    width: 53px
    height: 48px
    display: flex
    justify-content: center
    align-items: center
    margin-right: 10px
    border-bottom: 1px solid $c-grey
    box-sizing: border-box

  &__container
    overflow-y: auto
    width: stretch
    align-items: center
    display: flex
    flex-direction: column

  &__close
    position: absolute
    right: 20px
    top: 20px
    cursor: pointer

    +mq($until: phone)
      display: none

    &:hover
      .icon
        +icon-color($c-orange)

    .icon
      +icon-size(18px)

  ul
    width: 280px

    li
      position: relative
      margin-bottom: 20px

      .notification
        display: flex
        justify-content: center
        align-items: center
        color: $c-white
        font-weight: bold
        width: 30px
        height: 30px
        background-color: $c-notification-red
        border-radius: 50%
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 10px
        z-index: 10
        box-sizing: border-box
        padding-top: 5px

    li:last-of-type
      margin-bottom: 32px

  .button
    padding-right: 48px
    width: 100%

  &__wrapper
    z-index: 200
    background-color: $c-white
    padding:
      //left: 20px
      //right: 20px
      top: 50px
    min-width: 320px
    height: 100%
    position: fixed
    right: calc(-100% - 40px)
    top: 0
    +transition
    box-sizing: border-box
    overflow-y: auto

    +mq($until: phone)
      width: calc(100%)
      display: flex
      flex-direction: column
      align-items: center
      padding:
        left: 0
        right: 0
        top: 0
      +transition

    &--open
      right: 0
      +transition

      +mq($from: phone)
        &.main-menu__wrapper--search
          width: 70%
          +mq($from: tablet)
            width: 50%

  &__profile
    width: stretch
    padding: 0 20px
    display: flex
    justify-content: flex-start
    align-items: center
    position: relative
    margin-bottom: 40px

    +mq($until: phone)
      width: 250px
      margin-bottom: 30px
    //+mq($from: phone)
    //  display: flex !important

    &-content
      display: flex
      flex-direction: column

  &__image
    border: 6px solid $c-white
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    height: 68px
    width: 68px
    object-fit: cover
    margin-right: 20px
    position: relative
    +drop-shadow

    +mq($until: phone)
      display: none

    .image
      height: 100%

      img
        height: 100%
        object-fit: cover

    &-edit
      position: absolute
      right: 16px

      .icon
        +icon-size(16px)

  &__greeting
    +fontsize(24px, 1.16)
    margin-bottom: 4px

  &__username
    +fontsize(24px, 1.16)
    font-weight: bold
    margin-bottom: 4px
    max-width: 200px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__link-list
    max-width: 250px
    width: 100%

    +mq($until: phone)
      text-align: center
    //+mq($from: phone)
    //  display: block !important

    li
      display: block
      margin-bottom: 20px

  &__overlay
    width: 100%
    height: 100%
    position: fixed
    background-color: rgba(0, 0, 0, 0.8)
    top: 0
    left: 0
    right: 0
    bottom: 0
    pointer-events: all
    z-index: 199
    +transition
    visibility: hidden
    //animation: fadeOut 0.3s forwards

    &--visible
      display: block
      animation: fadeIn 0.3s forwards
      +transition


// Sonderfall
.main-menu__container
  +mq($until: phone)
    flex-direction: initial
    width: 100%
    flex-wrap: wrap
    justify-content: center