.content-wrapper
  width: 100%
  box-sizing: border-box

.content-wrapper-inner
  max-width: 970px
  margin: 0 auto

.light-mode
  .content-wrapper
    background-color: $c-white  

.dark-mode
  .content-wrapper
    background-color: $c-darkblue  