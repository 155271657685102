.apps-navigation
  box-sizing: border-box
  width: 100%
  position: relative
  padding-bottom: 40px

  +mq($until: tablet)
    padding: 0 20px 40px

  ul
    list-style: none
    margin: 0
    padding: 0

    li
      margin-bottom: 20px
      background-color: $c-lightgrey
      border-top-left-radius: 8px
      border-bottom-left-radius: 8px
      border-top-right-radius: 36px
      border-bottom-right-radius: 36px
      padding: 20px
      display: flex
      align-items: center
      flex-wrap: wrap

      +mq($until: mobile)
        border-radius: 10px

  &__title
    flex: 0 0 65%
    max-width: 65%
    text-align: left

    +mq($until: tablet)
      flex: 0 0 50%
      max-width: 50%
    
    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      text-align: center

    h3
      display: flex
      align-items: center

      +mq($until: mobile)
        justify-content: center
        margin-bottom: 15px

  &__icon
    svg use
      fill: $c-white

  &__text
    margin-left: 10px
    font-size: 32px

    +mq($until: tablet)
      font-size: 24px

  &__buttons
    flex: 0 0 35%
    max-width: 35%
    text-align: right

    +mq($until: tablet)
      flex: 0 0 50%
      max-width: 50%

    +mq($until: mobile)
      flex: 0 0 100%
      max-width: 100%
      text-align: center

    a
      margin-right: 20px
      text-decoration: none
      font-size: 18px
      transition: all 0.3s ease-in-out

      &:hover
        text-decoration: underline

      &.button
        display: inline-block
        margin: 0
        padding: 0 40px
        height: 42px
        line-height: 42px

        &:hover
          text-decoration: none

    button
      height: 42px
      line-height: 42px
      padding: 0 40px

.light-mode 
  .apps-navigation
    li
      background-color: $c-lightgrey

    h3, a
      color: $c-black

      &.button
        color: $c-white

        &:hover
          color: $c-white

.dark-mode
  .apps-navigation
    li
      background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

    h3, a
      color: $c-white

      &.button
        color: $c-white

        &:hover
          color: $c-darkblue