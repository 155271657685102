.counter
  justify-content: center
  align-items: center
  border-radius: 8px
  box-sizing: border-box
  height: 48px
  width: 33px
  flex-direction: column
  transform: translateY(0)
  line-height: 1
  transition-delay: 0.375s
  transition: transform 1.2s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out

  +mq($from: mobile)
    height: 80px
    width: 55px
    transition: transform 1.2s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out

  .counter-spawner--small &
    height: 48px
    width: 33px

  &__value
    display: flex
    flex: 0 0 100%
    height: 100%
    font-weight: bold
    padding-left: 8px
    padding-right: 8px
    padding-top: 6px
    box-sizing: border-box
    justify-content: center
    align-items: center
    background-color: $c-darkblue
    border-radius: 8px
    +fontsize(33px, 1.42)
    transition: transform 1.2s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, font-size 0.3s ease-in-out

    +mq($from: mobile)
      +fontsize(70px, 1.28)

    .counter-spawner--small &
      +fontsize(33px, 1.42)

    &:not(:last-of-type)
      margin-bottom: 5px

    .counter--standard &
      color: $c-white

    .counter--highlight &
      color: $c-orange

    .counter--light &
      background-color: $c-white

  &-spawner
    position: relative
    display: flex
    overflow: hidden
    min-width: max-content
    height: max-content

    .icon
      margin-top: auto
      padding: 0 6px 12px 6px
      +icon-size(8px)
      min-width: 8px
      +icon-color($c-darkblue)
      +transition

      +mq($until: mobile)
        padding: 0 6px 6px 6px
        +transition

.counter + .counter
  margin-left: 6px

.counter-spawner + .counter-spawner
  margin-top: 12px
