.schaffe-ich-header__section
  background-image: url("/images/app/schaffe-ich/img--schaffe-ich-bg.jpg")
  background-position: bottom
  min-height: 412px
  overflow: hidden
  +transition

  .app-header__box
    display: block
    width: 440px
    height: 300px
    margin: 0
    right: auto
    left: 50%
    bottom: -60px
    transform: translateX(-50%)

  &.app-header__section--open
    height: auto
    +transition

    & ~ .schaffe-ich-room__section .schaffe-ich-room__information
      max-height: 40em

      +mq($from: mobile)
        display: none

    .app-header__left
      p, h3
        display: none
        width: 480px
        +mq($from: mobile)
          display: block
        +mq($from: tablet)
          max-width: 380px

    +mq($from: desktop)
      .schaffe-ich-room__timer-wrapper
        display: flex
        
      & ~ .schaffe-ich-room__section .schaffe-ich-room__timer-wrapper
        display: none

      .app-header__box
        width: 880px
        height: 600px
        margin: 0
        right: auto
        left: 40%
        transform: translateX(0%)

  +mq($from: mobile)
    background-position: bottom center
    min-height: 540px
    &.app-header__section--open
      height: auto

      .app-header__box
        bottom: -80px
        +transition

    .app-header__box
      width: 420px
      height: 285px
      margin: 0
      transform: translateX(-50%)
      right: auto
      left: 50%
      +transition

  +mq($from: tablet)
    min-height: 310px

    & > video
      height: 820px
      transform: translateY(-65%)
      +transition

    .app-header__box
      width: 440px
      height: 300px
      margin: 0
      transform: translateX(0%)
      left: 55%
      right: auto

    &.app-header__section--open
      height: auto

      & > video
        transform: translateY(-50%)
        height: 100%
        +transition


  .app-header__heading h1
    text-align: center

  .schaffe-ich-room__timer-wrapper
    display: none
    position: absolute
    flex-direction: row
    z-index: 50
    background-color: $c-darkblue
    padding: 20px
    align-items: center
    max-width: 530px
    max-height: 166px
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    right: 90px
    bottom: 0

    & > h2
      +fontsize(22px, 28px)
      font-weight: normal

    .salespush-performance__box--timer
      max-width: max-content
      max-height: 126px
      padding: 20px 10px
      margin-left: auto
      margin-right: 0

      .progress-circle
        font-size: 86px

        & > span:first-of-type
          font-size: 39px
          line-height: 39px
