.styleguide

  .navbar__container
    position: relative

.navbar

  &__container
    width: 100%
    max-width: 1440px
    background-color: transparent
    height: 60px
    display: flex
    align-items: center
    justify-content: space-between
    overflow: visible
    position: relative
    z-index: 100
    box-shadow: 0 2px 8px rgba($c-black, .16)
    +transition

    &--black
      background-color: $c-black
      +transition

    &--white
      background-color: $c-white
      +transition

    &--grey
      background-color: $c-darkgrey

    &--scroll
      position: fixed
      top: -60px
      +transition

      +mq($until: mobile)
        top: 0

    &--scrolling-up
      top: 0
      +transition

    +mq($until: mobile)
      height: 48px
      position: fixed
      top: 0

  &__header
    width: 180px
    height: 80px
    margin-left: 130px
    align-self: flex-start
    z-index: 101
    box-shadow: 0 2px 8px rgba($c-black, .16)
    +transition

    +mq($until: tablet)
      margin-left: 44px
    +mq($until: mobile)
      margin-left: 20px

    +mq($from: mobile)
      .navbar__container--scroll &
        transform: translateY(-20px)
        +transition

      .navbar__container--scrolling-up &
        transform: translateY(0)
        +transition

    +mq($until: mobile)
      height: 48px
      width: 127px
      margin-left: 0
      box-shadow: none

    @media screen and (max-width: 1450px)
      margin-left: 0

  &__logo
    display: block
    height: inherit
    overflow: hidden

    .logo
      height: inherit
      display: block
      background: $c-white
      transition: none

      +mq($until: mobile)
        width: 127px

      img
        object-fit: cover
        width: auto
        height: 100%
        top: 50%
        left: 50%
        position: relative
        transform: translate(-50%, -50%)
        max-height: 100%
        max-width: 100%

  &__user
    position: relative
    margin-right: 130px
    display: flex
    align-items: center
    height: 60px
    z-index: 101
    background-color: $c-black
    // flex-grow: 1
    +transition

    .navbar__container--grey &
      background-color: $c-darkgrey

    +mq($until: mobile)
      background-color: $c-white

    .button--has-icon.button--noOutline
      padding: 0 0 0 32px
      line-height: 50px
      color: $c-white
      margin-right: 20px
      margin-left: 0

      +mq($until: desktop)
        margin-right: 15px

      .navbar__container--white &
        color: $c-black

      .icon
        left: 0
        +icon-size(20px)
        +icon-color($c-white)

        .navbar__container--white &
          +icon-color($c-black)

      &:hover, &:focus
        width: auto
        padding: 0 0 0 32px
        line-height: 50px
        transform: none
        color: $c-turquoise

        .icon
          +icon-color($c-turquoise)

    .button:first-of-type
      margin-left: auto
      +mq($until: mobile)
        display: none

    .button.navbar__categories-btn
      .icon
        top: 50%
        width: 16px
        height: 16px
        transform: translateY(-50%) rotate(90deg)
        transform-origin: center
        +transition

        .navbar__container--open &
          transform: translateY(-50%) rotate(-90deg)
          +transition

    .button.navbar__home-btn
      //margin-left: auto
      margin-right: 20px

      &:hover, &:focus
        width: auto
        padding: 0 20px
        transform: none
        color: $c-turquoise

    .button:last-of-type
      margin-left: 20px
      margin-right: 0

      .navbar__container--loggedOut &
        margin-left: auto

        .icon
          +icon-size(20px)
          transform: none

    +mq($until: tablet)
      margin-right: 44px

    +mq($until: mobile)
      height: 48px
      margin-right: 0

      .button
        display: none
      .button:nth-of-type(2)
        margin-left: auto

    +mq($from: phone)
      +mq($until: mobile)
        .button.navbar__logout-btn
          display: none

    @media screen and (max-width: 1450px)
      margin-right: 0

    &-bar
      height: 100%
      width: 140px
      background-color: $c-orange
      padding: 10px
      box-sizing: border-box
      justify-content: center
      position: relative
      margin-left: auto
      display: block

      .navbar__micro-user &
        padding: 5px 10px

      .navbar__container--club &
        background-color: $c-gold

      .navbar__user &
        +drop-shadow

      +mq($from: mobile)
        margin-left: 0

      +mq($until: mobile)
        min-width: max-content
        padding: 7px 10px 10px

      .icon
        position: absolute
        right: 8px
        bottom: 8px
        transform: rotate(90deg)
        +icon-color($c-white)
        +icon-size(15px)
        +transition

        .navbar__container--mobile-open &
          transform: rotate(270deg)
          +transition

        +mq($from: mobile)
          display: none

  &__logout-btn
    display: block

    .navbar__container--loggedOut &
      display: none

  @keyframes backAndForth
    0%
      transform: translateX(0)
    10%
      transform: translateX(0)
    45%
      transform: translateX(-100%) translateX(120px)
    55%
      transform: translateX(-100%) translateX(120px)
    90%
      transform: translateX(0)
    100%
      transform: translateX(0)

  &__username
    color: $c-white
    width: 100%
    overflow: hidden
    max-width: 120px
    display: block
    line-height: 0

    &-inner
      +fontsize(14px, 1.28)
      white-space: nowrap
      overflow: hidden
      display: inline-block

      &--scroll
        animation: backAndForth 5s linear infinite

        .oldie &
          animation: none

  &__balance
    color: $c-white
    width: 100%
    overflow: hidden
    max-width: 120px
    display: block

    &-inner
      font-weight: bold
      display: inline-block
      white-space: nowrap
      overflow: hidden
      +fontsize(20px, 1.11)

      +mq($until: mobile)
        padding-right: 18px
        +fontsize(16px, 1.11)

      &--scroll
        animation: backAndForth 5s linear infinite


  &__menu-button--mobile
    width: 53px
    height: 48px
    background-color: $c-darkgrey
    display: flex
    justify-content: center
    align-items: center

    .icon
      +icon-color($c-white)
      +icon-size(20px)

    +mq($from: mobile)
      display: none

  &__category-button
    //IE11
    position: fixed
    // position: sticky
    // height: 60px
    top: 48px
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center
    +drop-shadow
    padding-right: 21px
    background-color: $c-white
    z-index: 98
    box-sizing: border-box
    padding-top: 10px
    padding-bottom: 10px

    +mq($from: mobile)
      display: none

    b
      padding-right: 7px

    .icon
      +icon-size(16px)
      transform: rotate(90deg)
      +transition

      .navbar__container--open ~ &
        transform: rotate(-90deg)
        +transition

  // Language Switcher
  &__language-switch
    color: $c-white
    margin-right: 20px

    +mq($until: tablet)
      display: none

    button
      padding: 0
      margin: 0 5px

      &:last-of-type
        margin: 0 5px !important

      &:hover, &:focus
        width: auto

    &--menu
      display: none
      color: $c-black
      float: right
      position: relative
      top: 0

      +mq($until: tablet)
        display: block

      button
        color: $c-black !important
        padding: 0
        margin: 0 5px
        line-height: inherit
        height: auto

        &:last-of-type
          margin: 0 5px !important

        &:hover, &:focus
          width: auto

// Sonderfall
.navbar__user .no-shop-access-btn
  &:hover
    width: auto !important