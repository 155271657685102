.account

  &-header

    &__section
      padding: 60px 130px
      background-color: $c-darkblue
      color: $c-white
      +transition

      +mq($until: tablet)
        padding: 20px 44px
        +transition

      +mq($until: phone)
        padding: 20px 10px
        +transition

      & > h2
        text-align: center
        padding-bottom: 40px

        +mq($until: phone)
          padding-bottom: 20px

      .accordion
        margin-top: 0
        margin-bottom: 20px

        .icon
          +icon-color($c-white)

        .account__actions
          margin: 0 auto

          .button:first-of-type .icon
            +icon-color($c-black)

          .flatpickr-input.flatpickr-mobile
            padding-left: 20px
            height: 48px
            width: 195px
            box-sizing: border-box
            border: none
            color: $c-black
            background: $c-lightgrey
            border-radius: 8px
            +fontsize(18px, 1.33)

            &::placeholder
              color: $c-black

            .textfield--disabled &
              pointer-events: none
              color: $c-grey

              &::placeholder
                color: $c-grey


    &__content
      display: flex

      +mq($until: mobile)
        flex-wrap: wrap

  &__left
    margin-bottom: 40px
    flex-shrink: 100
    flex-grow: 100
    border-radius: 8px
  //width: stretch

  &__right
    margin-left: 20px
    position: relative

    +mq($until: mobile)
      flex: 1
      margin-left: 0


  &__filter
    display: none
    flex-direction: column
    padding-bottom: 60px

    +mq($from: mobile)
      display: flex

    & > h3
      padding-bottom: 21px

    & > h2
      color: $c-orange

    & > b:last-of-type
      padding-top: 20px

  &__info--mobile
    display: none

    div
      padding-left: 20px
      display: flex
      flex-direction: column
      width: stretch

      b
        max-width: 120px

      &:first-of-type > h4
        font-weight: normal
        color: $c-white

      h4
        color: $c-orange

    +mq($until: mobile)
      justify-content: space-between
      display: flex

  &__actions
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    width: 280px
    padding: 20px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 8px
    margin-bottom: 40px
    box-sizing: border-box
    +drop-shadow

    &--mobile
      display: block

      +mq($from: mobile)
        display: none

    .orders__date:last-of-type
      padding-bottom: 20px

    .button
      padding-left: calc(50% - 48px)
      text-align: left
      width: 240px
      margin-left: 0

      &:first-of-type
        margin-bottom: 10px

  &__transaction
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 48px
    width: 100%
    margin-bottom: 20px
    padding: 16px 20px
    box-sizing: border-box
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    border-radius: 8px
    +drop-shadow

    +mq($until: tabletMobile)
      span
        margin-bottom: 5px

    span:nth-child(1),
    span:nth-child(3),
    span:nth-child(4)
      width: 20%
      flex: 0 0 20%

      +mq($until: tabletMobile)
        width: 100%
        flex: 0 0 100%

    span:nth-child(2)
      width: 40%
      flex: 0 0 40%   

      +mq($until: tabletMobile)
        width: 100%
        flex: 0 0 100%  

    span:nth-child(4)
      text-align: right

      +mq($until: tabletMobile)
        text-align: left

    b
      width: 20%
      text-align: right

      +mq($until: tabletMobile)
        text-align: left

    &-labels
      display: flex
      flex-wrap: wrap
      padding: 0 20px 21px 20px

      h3:nth-child(1),
      h3:nth-child(3),
      h3:nth-child(4)
        width: 20%
        flex: 0 0 20%

        +mq($until: tabletMobile)
          width: 100%
          flex: 0 0 100%

      h3:nth-child(2)
        width: 40%
        flex: 0 0 40%      

        +mq($until: tabletMobile)
          width: 100%
          flex: 0 0 100%

      h3:nth-child(4)
        text-align: right

        +mq($until: tabletMobile)
          text-align: left

    &-container
      display: flex
      flex-direction: column
