.casino-room

  &__section
    padding: 60px 10px 20px 10px
    //display: flex
    //flex-direction: column
    background-color: $c-darkblue
    position: relative

    +mq($from: desktop)
      padding: 40px 60px

    .app-header__actions--mobile
      position: absolute
      top: 0
      display: block
      justify-content: center
      left: 50%
      transform: translate(-50%, -50%)

  &__content
    //display: flex
    //flex-direction: column

    .card-slider__label
    color: $c-white

    +mq($from: tablet)
      align-items: center

  &__wrapper
    width: stretch
    display: flex
    flex-direction: column
    margin-bottom: 40px
    +transition

    +mq($from: tablet)
      //margin-bottom: 80px
      justify-content: center
      align-items: center
      +transition

    +mq($from: desktop)
      flex-direction: row
      flex-wrap: wrap
      align-items: flex-start

  &__boxes
    display: flex
    flex-direction: column

    .lottie__uncontrolled svg
      transform: translate3d(0px, 2px, 0px) !important

    +mq($from: desktop)
      flex-direction: row
      flex-wrap: wrap
      align-items: flex-start

  &__item
    display: flex
    width: 100%
    border-radius: 8px
    align-items: center
    box-sizing: border-box

    &:not(:last-of-type)
      margin-bottom: 10px

    &--current
      color: $c-orange

      .icon
        +icon-color($c-orange)

    & > span
      height: 48px
      box-sizing: border-box
      background: $c-darkblue
      border-radius: 8px
      align-items: center
      font-weight: bold
      padding: 15px 10px
      display: flex
      justify-content: flex-start
      +transition

      +mq($from: mobile)
        height: 72px
        padding: 15px 20px
        +transition

      .icon
        display: none
        margin-right: 20px
        +icon-size(36px)
        +icon-color($c-white)

        +mq($from: mobile)
          display: block

      &:last-of-type
        min-width: 120px
        width: stretch
        +fontsize(18px, 9px)
        +transition

        +mq($from: mobile)
          +fontsize(42px, 42px)
          +transition

      & > sup
        margin-left: 5px
        margin-bottom: 10px
        font-size: 14px
        +transition
        +mq($from: mobile)
          margin-bottom: 20px
          +transition

      &:first-of-type
        min-width: 120px
        width: stretch
        margin-right: 10px
        +fontsize(18px, 18px)

  &__information
    display: block
    color: $c-white
    max-height: 0
    overflow: hidden
    +transition
    max-width: 480px
    margin: 0 auto

    +mq($from: mobile)
      display: none

    & > h3
      margin-bottom: 20px

    &--open
      max-height: 25em
      +transition

    .button
      margin-top: 20px
      left: 50%
      transform: translateX(-50%)

  &__bandit

    &-handle
      position: relative
      cursor: pointer

      .lottie__controlled
        width: 66px
        height: 316px
        display: block
        +mq($until: mobile)
          width: 54px
          display: block
          +transition

      +mq($from: mobile)
        margin-top: -45px
        +transition

      &--knob
        width: 38px
        height: 38px
        background-color: $c-grey
        overflow: visible
        position: absolute
        top: 50%
        border-bottom-right-radius: 8px
        border-top-right-radius: 8px

        +mq($from: mobile)
          width: 52px
          min-height: 52px
          height: 52px

    &-container
      display: flex
      position: relative
      width: stretch
      justify-content: center

      +mq($from: mobile)
        justify-content: flex-end

      & > .dotted-circle
        left: 50% !important
        transform: translateX(-50%) rotate(150deg) !important
        +transition

        +mq($from: mobile)
          left: 0 !important
          transform: rotate(150deg) !important
          +transition

    &-wrapper
      box-sizing: border-box
      border-radius: 8px
      border: 13px solid $c-grey
      background-color: $c-grey
      margin-top: 82px
      width: 236px
      height: 134px

      +mq($from: mobile)
        width: 318px
        min-height: 181px
        height: 181px
        margin-top: 52px
        margin-left: 66px
        +transition

      &--inner
        border-radius: 4px
        height: 100%
        display: flex
        background-color: $c-white
        align-items: center
        justify-content: center

      .counter-spawner
        margin: 0 10px 0 0

        .counter
          width: 52px
          height: 77px
          +mq($from: mobile)
            width: 71px
            height: 104px
            +transition

          & + .counter
            margin-left: 10px

          &__value
            padding-top: 12px
            +fontsize(68px, 68px)

            +mq($from: mobile)
              +fontsize(92px, 92px)
              +transition

        &:last-of-type
          margin-right: 0

  &__dotted-circle
    position: absolute
    top: 50%
    z-index: 20
    left: 50%
    transform: translate(-50%, -50%) rotate(-90deg)
    width: 110%
    height: 110%

  &__slots
    color: $c-white
    font-weight: bold
    justify-content: center
    align-items: center
    background-color: $c-darkred
    border: 8px solid white
    border-radius: 50%
    display: flex
    transform: rotate(180deg)
    width: 118px
    height: 118px
    +drop-shadow

    & > h1
      height: 76px
      +fontsize(80px, 80px)

    +mq($from: mobile)
      width: 180px
      height: 180px
      +transition

      & > h1
        height: 96px
        +fontsize(105px, 105px)

        .oldie &
          line-height: 70px

    &-wrapper, &-available, &-headline, &-subline, &-lower
      position: relative
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

    &-wrapper
      display: flex
      margin-bottom: 40px
      z-index: 10

      +mq($from: tablet)
        margin-bottom: 60px
        margin-top: -90px
        +transition

    &-available
      transform: rotate(180deg)
      width: 144px
      height: 144px

      +mq($from: mobile)
        width: 214px
        height: 214px
        +transition

    &-lower
      position: relative
      margin-top: -40px

    &-headline
      background-color: $c-orange
      border-radius: 8px
      color: $c-white
      padding: 10px 25px
      font-weight: bold
      +drop-shadow
      z-index: 5
      width: max-content

      & > h2
        +fontsize(24px, 24px)
        +mq($from: mobile)
          +fontsize(36px, 36px)
          +transition

    &-subline
      background-color: $c-white
      color: $c-black
      padding: 10px 15px
      border-bottom-left-radius: 8px
      border-bottom-right-radius: 8px
      z-index: 4
      width: max-content

      & > p
        +fontsize(12px, 12px)

        +mq($from: mobile)
          +fontsize(18px, 18px)
          +transition

  &__box
    border-radius: 8px
    display: flex
    flex-direction: column
    color: $c-white
    padding: 20px 10px
    box-sizing: border-box
    align-items: center
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    width: 100%
    width: stretch
    height: auto
    max-width: 480px
    position: relative
    z-index: 20
    +transition

    +mq($from: mobile)
      padding: 17px 17px 40px
      +transition
    +mq($from: tablet)
      width: 480px
      min-height: 328px
      height: 328px
      +transition

    &--action
      background-color: $c-white
      border-radius: 50%
      width: 46px
      height: 46px
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      bottom: 0
      transform: translateY(50%)
      cursor: pointer
      +drop-shadow

      .icon
        +icon-size(30px)

      .icon--close
        +icon-size(20px)

    &-accordion
      //position: absolute
      background-color: $c-white
      max-width: 440px
      width: 100%
      width: stretch
      border-radius: 8px
      z-index: 2
      height: 112px
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      padding-top: 20px
      box-sizing: border-box
      margin-top: -140px
      +transition

      &--open
        margin-top: -28px
        color: $c-black
        +transition
        +mq($from: desktop)
          margin-top: -14px
          +transition

    &--bandit
      padding: 9px 20px 33px 10px

      +mq($until: mobile)
        height: 266px
        +transition
      +mq($from: tablet)
        padding: 20px 20px 33px 10px
        height: 328px

    &--wrapper
      display: flex
      flex-direction: column
      align-items: center
      position: relative

      &:not(:last-of-type)
        margin: 0 0 62px 0
        +transition

        +mq($from: desktop)
          margin: 0 20px 0 0
          +transition

      .dotted-circle
        position: absolute
        top: 0
        left: 50%
        transform: translate(-50%, -50%)
        display: flex
        justify-content: center
        align-items: center
        width: 102px
        height: 102px

        +mq($from: mobile)
          width: 122px
          height: 122px
          +transition

        & > .jackpot-counter__icon
          left: auto
          position: relative
          transform: rotate(-150deg)
          width: 88px
          height: 88px

          .lottie__uncontrolled
            width: 88px
            height: 88px

          +mq($from: mobile)
            width: 100px
            height: 100px
            +transition

      & > h2
        margin-bottom: 20px
        color: $c-white

    &--safe .dotted-circle
      transform: translate(-50%, -50%) rotate(180deg)

      & > .jackpot-counter__icon
        transform: rotate(-180deg)

    &-container > .jackpot-counter
      top: auto
      margin-top: 52px
      margin-bottom: 0
      height: auto
      padding: 50px 17px 10px

      +mq($from: mobile)
        padding: 70px 20px 24px
        +transition

      .jackpot-counter__wrapper
        & > p
          display: none

        +mq($until: mobile)
          text-align: center
          flex-direction: column

          & > p
            display: block
          & > h5
            display: none

    &-header
      display: flex
      width: stretch
      margin-bottom: 25px

      div
        display: flex
        justify-content: flex-start
        align-items: center
        flex: 50 50
        padding: 0 0 0 10px

        +mq($from: mobile)
          h4
            +fontsize(24px, 1.16)
            +transition


    +mq($until: desktop)
      &:not(:last-of-type)
        margin-bottom: 20px

    & > h3
      margin-bottom: 20px
      text-align: center

      +mq($from: mobile)
        text-align: left

    &-container
      display: flex
      flex-direction: row
      width: 100%

      &:not(:last-of-type)
        margin-bottom: 20px

      &--list
        width: stretch
        flex-direction: column

    &-wrapper
      display: flex
      margin-bottom: 20px
      +transition

      &:not(:last-of-type)
        margin-right: 20px

      .counter
        width: 33px
        height: 48px

        &__value
          +fontsize(33px, 33px)

    &-type
      justify-content: center
      display: flex
      +transition

    & > a
      margin-top: 40px

  &__actions
    margin-top: 40px
    display: flex
    width: stretch
    justify-content: center

