.splitview
  width: 100%
  min-height: max-content
  display: flex
  flex-direction: row

  +mq($from: mobile)
    min-height: 900px
    height: 900px

  &--main
    max-width: 810px
    background: transparent url('../../assets/images/bdm-login-city-default-still.jpg') 0 0 no-repeat padding-box
    background-size: cover
    display: none

    +mq($from: mobile)
      display: block
      flex: 2 2
    +mq($from: desktop)
      flex: 2 1 430px

  &--sidebar
    flex: 2 2
    background-color: $c-white
    padding: 160px 20px 0 20px

    +mq($from: mobile)
      padding: 160px 60px 0 60px

    +mq($from: desktop)
      padding: 160px 120px 0 120px
