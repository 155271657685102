.styleguide

  & > .button-points
    margin-left: 50px

.button-points
  width: 273px
  height: 48px
  box-sizing: border-box
  border-radius: 32px
  color: $c-white
  +fontsize(18px, 24px)
  font-weight: bold
  text-transform: uppercase
  cursor: pointer
  position: relative
  background-color: $c-orange
  padding: 0 0 0 44px
  border: 0
  +transition
  -webkit-font-smoothing: antialiased
  display: flex
  align-items: center
  justify-content: center
  +drop-shadow

  +mq($until: desktop)
    width: 243px

  &__icon
    height: 44px
    width: 44px
    box-sizing: border-box
    display: inline-flex
    border-radius: 50%
    background-color: $c-white
    position: absolute
    left: 2px
    top: 2px
    +drop-shadow

    .icon
      left: 50%
      width: 25px
      height: 25px
      top: 50%
      transform: translate(-50%, -50%)

  &:hover, &:focus
    padding-top: 0
    padding-bottom: 0
    letter-spacing: 0
    //transform: scale(1.05)
    +scale
    +transition

  &--inactive
    cursor: unset
    pointer-events: none
    color: $c-brightgrey
    background-color: $c-lightgrey
    border-color: $c-brightgrey

    &.button-points--secondary
      background-color: unset

    &:hover, &:focus
      width: 180px
      color: $c-brightgrey
      border-color: $c-brightgrey
      background-color: $c-lightgrey

