.styleguide

  & > .shipping-box
    margin-left: 50px
    border-radius: 8px

.shipping-box
  max-width: 380px
  width: stretch
  min-height: max-content
  border-radius: 8px
  text-align: left
  margin-bottom: 20px

  &__content
    padding: 20px
    background-color: $c-white
    margin-bottom: 20px
    border-radius: 8px
    +transition

    .checkout__right--shipping &
      +drop-shadow-darker

    .checkout__left--focus &
      box-shadow: none
      opacity: 0.4
      +transition

  &__item
    display: flex

    .shipping-box__row
      margin-bottom: 0

  &__item:not(:last-of-type)
    padding-bottom: 20px
    border-bottom: 1px solid $c-brightgrey
    margin-bottom: 20px

  &__row
    display: flex
    flex-direction: row
    margin-bottom: 10px
    width: 100%

    &--nm
      margin-bottom: 0

    &--email
      position: relative

      .shipping-box--overview &
        margin-bottom: 40px

  &__details
    display: flex
    flex-direction: column
    width: 100%

  &__heading
    +fontsize(24px, 1.16)
    font-weight: bold
    display: block
    margin-bottom: 40px

    .shipping-box--overview &
      margin-bottom: 30px


  &__label, &__info
    +fontsize(16px, 1.5)

  &__info
    display: flex

    span + .button
      margin-left: auto
      font-size: 16px
      line-height: 1.35
      font-weight: normal
      height: 18px
      display: flex
      justify-content: flex-start
      align-items: center
      padding-left: 24px
      padding-right: 0

      .icon
        +icon-size(16px)
        left: 0

      &:hover, &:focus
        transform: none
        width: unset

    &:first-of-type
      text-transform: capitalize

  &__label
    padding-right: 5px

    &--bold
      font-weight: bold
      +fontsize(12px, 1.33)

  &__actions
    margin-top: auto
    padding: 20px
    background-color: $c-white
    border-radius: 8px
    min-width: 240px
    +drop-shadow
    +transition

    .checkout__right--shipping &
      +drop-shadow-darker

    .checkout__left--focus &
      box-shadow: none
      opacity: 0.4
      +transition

    a:first-of-type
      margin-bottom: 10px
      width: 100%
      display: block

    .button
      margin-top: 10px
      margin-left: auto
      margin-right: auto
      //max-width: 280px
      width: 100%

    button:first-of-type
      margin-top: 0

    .button--has-icon
      padding-left: 35px
