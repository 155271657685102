.heros-performance
  position: relative

  &__section
    background-color: $c-darkblue
    height: auto
    display: flex
    flex-direction: row
    box-sizing: border-box
    position: relative
    +transition

  &__box
    max-width: max-content
    width: stretch
    border-radius: 8px
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box
    padding: 20px
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: center
    +transition

    +mq($from: mobile)
      align-items: stretch

    +mq($from: tablet)
      max-height: 271px

    & > h3
      margin-bottom: 20px
      +mq($until: mobile)
        +fontsize(20px, 1.3)

    p
      display: none

    &-container
      flex-direction: column
      display: flex
      margin-bottom: 0
      width: stretch
      overflow-x: auto
      overflow-y: hidden
      +transition
      +mq($from: mobile)
        width: auto

    &-wrapper
      display: flex
      flex-direction: column

      +mq($from: mobile)
        flex-direction: row

      & > span
        display: flex

      p
        display: flex
        justify-content: center
        align-items: center
        margin-left: 10px
        text-align: left
        margin-top: 10px
        +mq($from: mobile)
          width: 113px

      &:first-of-type
        margin-right: 0

      &:not(:last-of-type)
        margin-bottom: 20px

      &:last-of-type h3
        color: $c-orange

      .counter
        width: 33px
        height: 48px
        +transition

        +mq($from: mobile)
          height: 80px
          width: 55px
          +transition

    &-type
      display: flex
      justify-content: center
      align-items: center
      background-color: $c-darkblue
      box-sizing: border-box
      border-radius: 8px
      margin-left: 8px
      width: 124px
      height: 48px
      padding: 0 8px
      white-space: nowrap
      +transition

      +mq($from: mobile)
        height: 80px
        width: 121px
        +transition

  &__content
    width: 100%
    color: $c-white
    z-index: 1
    display: flex
    padding: 40px 10px 0 10px
    justify-content: center
    align-items: center
    box-sizing: border-box
    flex-wrap: wrap
    +transition

    +mq($from: mobile)
      padding: 40px 10px
    +mq($from: tablet)
      align-items: stretch

    .heros-place-header__charts
      position: relative
      top: auto
      left: auto
      transform: none
      margin-right: 0
      max-width: 480px
      height: 170px
      width: 100%
      width: stretch
      margin-bottom: 20px
      padding: 10px
      box-sizing: border-box
      display: flex
      +transition

      h3
        +transition
        +mq($until: mobile)
          +fontsize(20px, 1.3)
          +transition

      +mq($from: mobile)
        padding: 20px
        height: 271px
        max-width: 680px
        +transition

      +mq($from: tablet)
        margin-right: 20px
        max-width: 480px
        +transition

        .heros-place-header__section--open &
          display: none

      .progress-circle
        font-size: 86px
        +transition

        +mq($until: mobile)
          span:first-of-type
            +fontsize(39px, 39px)
            +transition

        +mq($from: mobile)
          font-size: 180px
          +transition

        +mq($from: tablet)
          font-size: 120px
          +transition

    +mq($until: tablet)
      flex-direction: column
