=fontsize($fontsize, $lineheight)
  font-size: #{$fontsize}
  line-height: #{$lineheight}

=icon-color($color)
  svg use
    fill: #{$color}

=icon-size($size)
  width: $size
  height: $size

=transition
  transition: all 0.3s ease-in-out

=gradient-blue
  background: linear-gradient(top, #85bae0, #5489c2)

=gradient-blue-medium
  background: transparent linear-gradient(180deg, rgba(70, 70, 118, 1) 0%, rgba(54, 54, 105, 1) 100%) 0% 0% no-repeat padding-box

=gradient-blue-dark
  background: linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box

=gradient-gold
  background: transparent linear-gradient(180deg, rgba($c-brightgold, 1) 0%, rgba($c-gold, 1) 100%) 0% 0% no-repeat padding-box

=drop-shadow
  box-shadow: 0 2px 8px rgba($c-black, .16)

=drop-shadow-darker
  box-shadow: 0 2px 8px rgba($c-black, 0.32)

=scale($factor:1.05)
  transform: perspective(1px) scale($factor)
