.welcome-intro
  box-sizing: border-box
  width: 100%
  position: relative
  padding: 40px 0

  +mq($until: tablet)
    padding: 40px 20px

  h2
    font-size: 42px
    margin-bottom: 10px

    +mq($until: tablet)
      font-size: 28px

  h3
    margin-bottom: 30px

  p
    margin-bottom: 20px

  &--text
    h1
      font-size: 20px
      margin-bottom: 10px

    h2
      font-size: 18px
      margin-bottom: 10px

    i 
      font-style: italic

    b
      font-weight: bold

    ol
      list-style-type: decimal
      margin-left: 20px
      margin-bottom: 20px

    ul
      margin-left: 20px
      list-style-type: disc
      margin-bottom: 20px

.light-mode
  .welcome-intro
    h2, p, h3, a
      color: $c-black

.dark-mode
  .welcome-intro
    h2, p, h3, a
      color: $c-white