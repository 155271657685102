.teamTrophy
  .profit-section
    background-color: $c-lightgrey
    display: flex
    padding: 40px 60px

    .col
      flex: 100%

      +mq($from: tablet)
        flex: 50%
        padding-left: 60px
        padding-right: 60px

    &__icon
      position: absolute
      top: 0
      right: 0
      transform: translate(-20px, -20px)
      display: flex
      border-radius: 50%
      width: 80px
      height: 80px
      justify-content: center
      align-items: center
      background-color: $c-white
      +drop-shadow
      z-index: 5

      .lottie__uncontrolled
        width: 50px
        height: 50px

    &__image
      border-radius: 8px
      position: relative

    &__text


