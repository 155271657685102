.header__akkordeon
  padding: 60px 130px
  color: $c-white
  display: flex
  flex-wrap: wrap
  background-color: $c-darkblue
  justify-content: center

  +mq($until: desktop)
    padding: 60px 20px
  
  .header__akkordeon-inner
    display: flex
    flex-wrap: wrap
    max-height: 100px
    overflow: hidden
    transition: all 0.5s ease-in-out
    width: calc(70% - 10px)

    +mq($until: tablet)
      width: 100%
      max-height: 120px
    
    +mq($until: mobile)
      max-height: 200px

    &--active
      max-height: 10000px
      overflow: auto

  h2
    margin-bottom: 15px

  &__menu
    button
      .icon
        position: absolute
        right: 30px
        left: auto
        max-width: 20px
      
      &.button--has-icon
        padding: 0 60px 0 60px
        display: inline-block
        text-decoration: none
    
      &.button--icon-up
        .icon
          top: 10px
          transform: rotate(-90deg)
      
      &.button--icon-down
        .icon
          top: 13px
          transform: rotate(90deg)

  &__left
    width: calc(90% - 10px)
    padding-right: 10px
    margin-bottom: 40px

    +mq($until: tablet)
      width: 100%
      padding: 0

  &__right
    width: calc(30% - 10px)
    padding-left: 10px
    margin-bottom: 40px

    +mq($until: tablet)
      width: 100%
      padding: 0

    h4
      margin-bottom: 30px
      font-weight: 700
      margin-left: 30px

  &__box
    background-color: $c-blue
    padding: 30px
    border-radius: 8px

    h3
      text-transform: uppercase
      margin-bottom: 5px
      font-size: 22px

    button
      margin-bottom: 25px
      width: 100%
    
    .button--icon--right
      .icon
        position: absolute
        top: 26px
        right: 15px
        left: auto
        max-width: 20px
      
      &.button--has-icon
        padding: 0 20px 0 20px
        display: inline-block
        text-decoration: none

      &:last-child
        margin: 0