.header
  width: 100%
  max-width: 1440px
  height: 240px
  position: relative
  +transition

  +mq($until: tablet)
    height: 160px
    +transition

  &.header--no-image
    background-color: $c-white

    & > h1, & > h2
      color: $c-black
      text-transform: none

    .container--order &
      height: 286px
      margin-bottom: 0

  .image
    height: 100%

    img
      height: 100%
      width: 100%
      object-fit: cover

  h2, h1
    position: absolute
    margin-left: 130px
    color: $c-white
    text-transform: uppercase
    +transition

    +mq($until: tablet)
      margin-left: 44px
      +transition

    //.container--wishlist &
    //  +mq($until: desktop)
    //    margin-left: 60px
    //    +transition
    //
    //  +mq($until: phone)
    //    margin-left: 20px

    +mq($until: phone)
      margin-left: 20px

  h1
    bottom: 60px
    display: block

    +mq($until: tablet)
      bottom: 40px

      .container--checkout &
        bottom: 50px

  &--compl
    height: 315px

    h1
      max-width: 650px

.header
  .shop-header-headlines
    h1
      bottom: 80px
      font-size: 70px

      +mq($until: tablet)
        font-size: 40px
        bottom: 40px

      +mq($until: phone)
        font-size: 24px
        bottom: 45px

    h2
      bottom: 40px

      +mq($until: tablet)
        bottom: 5px

      +mq($until: phone)
        bottom: 20px