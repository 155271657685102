.hero-slider

  .tns-ovh
    border-radius: 8px

  button[data-action="stop"]
    display: none

  &__section
    padding: 60px 130px 0 130px
    background-color: $c-darkblue
    +transition

    +mq($until: tablet)
      padding: 60px 44px 0 44px
      +transition

    +mq($until: phone)
      padding: 10px
      +transition

  &__container
    position: relative
    padding-bottom: 52px

    .tns-nav
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)

      button
        appearance: none
        padding: 24px 0 24px 0
        width: 40px
        margin-right: 5px
        background-color: unset
        border: 0
        +transition

        &::after
          content: " "
          display: block
          border-bottom: 4px solid $c-brightgrey
          border-radius: 8px
          +transition

        &:hover, &:focus,
        &.tns-nav-active
          &::after
            border-color: $c-orange
            +transition

        &:last-of-type
          margin-right: 0

  &__slide
    position: relative
    border-radius: 8px
    //width: 1180px
    height: 442px

    .image
      height: 100%

      img
        border-radius: 8px
        height: 100%
        object-fit: cover
        width: 100%

    &-actions
      position: absolute
      bottom: 20px
      right: 20px

      & > a
        margin: 0 auto

    &-heading
      position: absolute
      bottom: 75px
      left: 50px
      +fontsize(64px, 1)
      color: $c-white
      font-weight: bold
      max-width: 580px
      text-shadow: 0 2px 8px rgba($c-black, .16)

      +mq($until: phone)
        +fontsize(24px, 1.16)

