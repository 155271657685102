.tachometer
  background: $c-darkblue
  width: 250px
  height: 250px
  border-radius: 100%
  display: block
  position: relative
  margin: 0 auto
  transform: rotate(-90deg)

  &__container
    overflow: hidden
    height: 135px

.ii
  position: absolute
  width: 250px
  height: 250px
  z-index: 2
  transform: rotate(90deg)

.ii div
  position: absolute
  width: 250px
  height: 250px
  padding: 4px
  box-sizing: border-box

.ii div b
  position: absolute
  display: block
  left: 50%
  width: 3px
  height: 10px
  background: #FFF
  margin: 0 -5px 0


.ii div:nth-child(2n + 1) b
  width: 5px
  height: 20px
  margin: 0 -5px 0

.ii div:nth-child(1)
  transform: rotate(270deg)

.ii div:nth-child(2)
  transform: rotate(285deg)

.ii div:nth-child(3)
  transform: rotate(300deg)

.ii div:nth-child(4)
  transform: rotate(315deg)

.ii div:nth-child(5)
  transform: rotate(330deg)

.ii div:nth-child(6)
  transform: rotate(345deg)

.ii div:nth-child(7)
  transform: rotate(360deg)

.ii div:nth-child(8)
  transform: rotate(375deg)

.ii div:nth-child(9)
  transform: rotate(30deg)

.ii div:nth-child(10)
  transform: rotate(15deg)

.ii div:nth-child(11)
  transform: rotate(30deg)

.ii div:nth-child(12)
  transform: rotate(45deg)

.ii div:nth-child(13)
  transform: rotate(60deg)

.ii div:nth-child(14)
  transform: rotate(75deg)

.ii div:nth-child(15)
  transform: rotate(90deg)

.ii div:nth-child(16)
  transform: rotate(105deg)

.ii div:nth-child(17)
  transform: rotate(120deg)

.line
  background: $c-orange
  height: 95px
  margin-top: -105px
  left: 50%
  position: absolute
  top: 50%
  width: 7px
  transform-origin: 50% 100%
  margin-left: -6px
  z-index: 2
  border-radius: 50% 50% 0 0

.pin
  width: 30px
  height: 30px
  left: 50%
  top: 50%
  margin: -25px 0 0 -18px
  background-color: $c-blue
  border-radius: 50%
  position: absolute
  z-index: 4
  +drop-shadow

.inner
  width: 15px
  height: 15px
  margin: 8px auto 0
  background-color: $c-darkblue
  border-radius: 100%
  position: relative