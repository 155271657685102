html,
html input
  font-family: 'Titillium', sans-serif !important

html.oldie,
html.oldie input
  font-family: 'TitilliumIE', sans-serif !important

body, html, #root
  min-height: 100vh
  width: 100%
  margin: 0

sup
  vertical-align: super
  font-size: smaller

#root
  display: flex

body
  background-color: $c-background
  color: $c-black
  min-width: 320px

a
  text-decoration: underline
  color: $c-black
  +transition

input
  &:focus, &:active
    outline: none
    border: 2px solid $c-brightgrey !important

*:focus, *:active
  outline: none

*::-webkit-scrollbar
  -webkit-appearance: none

*::-webkit-scrollbar:vertical
  width: 11px

*::-webkit-scrollbar:horizontal
  height: 11px

*::-webkit-scrollbar-thumb
  border-radius: 8px
  border: 2px solid white
  background-color: rgba($c-grey, .5)

.c-orange
  color: $c-orange

strong
  font-weight: bold

.linklist

  &__seperator
    +fontsize(12px, 1.33)

  a
    color: $c-black
    +fontsize(12px, 1.33)
    margin-right: 5px
    text-decoration: none

    &:not(:first-of-type)
      margin-left: 5px

    &:hover
      text-decoration: underline
      color: $c-orange
      +transition

b
  font-weight: bold

.navbar-margin
  margin-top: 60px !important

.mainContainer
  max-width: 1440px
  width: 100%
  margin: 0 auto
  display: flex
  flex-direction: column
  position: relative
  background-color: $c-background

  &--center
    +mq($from: mobile)
      justify-content: center

.content
  padding-left: 130px
  padding-right: 130px
  height: max-content
  margin: auto
  padding-top: 20px
  overflow: visible
  +transition

  +mq($until: tablet)
    padding-left: 60px
    padding-right: 60px
    +transition

  +mq($until: mobile)
    padding-left: 20px
    padding-right: 20px
    +transition

  &--rules
    max-width: 580px
    width: stretch
    margin: 40px auto 60px

    img
      width: 100%

    ul
      list-style: disc
      margin: 10px 0
      padding-left: 20px

    ol
      list-style: decimal
      margin: 10px 0
      padding-left: 20px

  &.app
    padding: 0

  &.wishlist, &.cart
    +mq($until: desktop)
      padding-left: 44px
      padding-right: 44px
      +transition

    +mq($until: mobile)
      padding-left: 20px
      padding-right: 20px
      +transition

  &.cart
    +mq($until: phone)
      padding: 0
      margin-top: 0

  &.cart, &.wishlist
    margin-top: 40px
    overflow: visible

  &.checkout
    background-color: $c-lightgrey
    margin-top: 0
    padding-top: 0
    position: relative
    padding-bottom: 40px
    overflow: visible
    +mq($until: phone)
      padding: 0 10px 80px 10px

  &.shop-home,
  &.private
    padding: 0

  &.private, &.news
    overflow: visible

  &.orders
    margin: 0
    padding: 20px 10px 20px 10px
    overflow: visible

    +mq($from: mobile)
      padding: 20px 44px

.container
  background-color: $c-white
  position: relative

  &--full-height
    height: 100%

  &--cart, &--wishlist, &--orders
    background-color: $c-lightgrey

  +mq($until: mobile)
    &--wishlist, &--cart
      margin-bottom: 80px

      & ~ .section__card-slider
        display: none

  &--checkout, &--orders
    padding-bottom: 100px

  +mq($until: mobile)
    margin-top: 48px

  +mq($from: mobile)
    &--scroll
      margin-top: 60px

  &--jackpot-counter
    @media screen and (max-width: 767px)
      margin-top: 86px

    @media screen and (max-width: 500px)
      margin-top: 110px

.styleguide
  padding: 24px
  width: 100%

  h2
    margin: 34px 0

.center-content
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.center-text
  text-align: center


.section

  &__card-slider
    padding: 60px 130px 100px 130px
    background-color: $c-white
    +transition

    +mq($until: tablet)
      padding: 60px 44px 100px 44px
      +transition

    +mq($until: phone)
      padding: 20px 0 20px 20px
      +transition

.empty-page ul li
  margin-top: 60px

.m-t-auto
  margin-top: auto

.oldie .oldie-hide
  display: none

.anchor
  height: 0
  line-height: 0
//visibility: hidden


// New Rules
[class^='content--rules-']
  margin-bottom: 50px
  padding-bottom: 50px
  border-bottom: 1px solid rgba(black, 0.2)

.teamtropy-countdown
  display: flex
  justify-content: center
  align-items: center

.back-to-login
  width: 100%
  text-align: right

  +mq($until: mobile)
    padding-right: 15px

  a
    color: $c-white
    text-decoration: none

.banner-section .banner-section-inner
  .app-header__box
    +mq($until: mobile)
      display: none !important

// App Stylings Banner
.private-header__content
  flex-wrap: wrap

.private-header__section .app-header__left
  +mq($until: tablet)
    display: block
    flex: 0 0 100%
    max-width: 100%

.private-header__section .app-header__right
  +mq($until: tablet)
    display: block
    flex: 0 0 100%
    max-width: 100%

.private-header__section .app-header__right .jackpot-counter
  +mq($until: tablet)
    margin-top: 0

.heros-ranking__row
  +mq($until: tablet)
    min-width: 640px

.schaffe-ich-room__box--wrapper
  +mq($until: tablet)
    flex: 0 0 100%
    max-width: 100%

.schaffe-ich-header__section .app-header__left .schaffe-ich-room__timer-wrapper
  +mq($until: desktop)
    display: none !important

.schaffe-ich-header__section .app-header__box
  top: 110px !important

  +mq($until: tablet)
    top: 130px !important

.schaffe-ich-header__section.app-header__section--open .app-header__left p, 
.schaffe-ich-header__section.app-header__section--open .app-header__left h3
  +mq($until: desktop)
    width: auto !important

table
  width: 100%
  margin: 10px 0

table th, 
table td
  padding: 0.75rem
  vertical-align: top
  border-top: 1px solid #dee2e6
  text-align: left
